@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body {
    font-family: 'Noto Sans KR', sans-serif !important;
}

.container {
    border-radius: 30px;
    margin-top: 15px;
    color: white;
    height: 270px;
    padding: 10px;
    background-color: #233886;
    position: relative;
}
.rise{
    background-color: #990000;
}

.neutral {
    background-color: gray;
    box-shadow: 0 0 10px gray;
}

.type {
    padding: 0.7rem;
    font-size: 1vw;
    white-space: nowrap;
}

.name {
    font-size: 15px;
    width: auto;
}

.detailed_info {
    border: white solid 1px;
    border-radius: 15px;
    font-size: 15px;
    width: auto;
    cursor: pointer;
    padding: 2px 5px;
    transition: all 0.5s;
}

.detailed_info:hover {
    background-color: white;
    color: black;
    transition: all 0.5s;
}

.id{
 font-size: 16px;
}

.title {
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 5px;
    /* padding-left: 15px; */
    font-size: 15px;
}

.percentage {
    justify-content: center;
    font-family: 'Signika', sans-serif;
    font-size: 30px;
    font-weight: bolder;
    padding-bottom: 1rem;
}

.candle_chart_button {
    position: absolute;
    bottom: 20px;
    right: 40px;
    padding: 5px;
    border: 1px solid white;
    border-radius: 10px;
    cursor: pointer;
    transition: all 1s;
}

.isAdministrative {
    justify-content: center;
    border: 1px solid;
    width: 30px;
    height: 30px;
    font-size: 12px;
    padding: 2px;
    border-radius: 30px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.detail_info_content {
    display: flex;
    justify-content: space-between;
    padding: 3px;
    border-bottom: 2px solid #c5c5c5;
}

.detail_info_content_header {
    color: #9e9e9e;
}

.detail_info_content_info {
    font-weight: bold;
}

.priceStyle {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}

@media only screen and (min-width: 900px)
{
    .candle_chart_button:hover {
        background-color: white;
        color: black;
        transition: all 1s;
    }  
}

@media only screen and (max-width: 900px)
{
    .name {
        font-size: 12px;
        width: auto;
    }
    
    .detailed_info {
        font-size: 12px;
    }

    .id {
        font-size: 12px;
    }

    .type {
        padding: 5px 0px;
    }
    
    .container {
        height: 250px;
    }
}

@media(max-width: 450px) {
     .name {
        font-size: 14px;
    }
    .id {
        font-size: 16px;
    }
     .type {
        padding-bottom: 0;
        padding-top: 0;
    }
    .container {
        height: 250px;
    }
    .priceStyle {
        font-size: 12px;
    }
}
