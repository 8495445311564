.home__container1 {
    background-color: #455964;
    height: 580px;
    display: flex;
    padding-top: 3rem;
    z-index: 9999;
}

.home__inner {
    margin: auto;
    width: 80%;

}

.home__content {
    display: flex;

    align-items: center;
    /* width: 300px; */

    /* flex:1 */


}

.home__content .home__left {
    flex: 0.6;

    flex-wrap: wrap;



}

h1 {
    color: #fff;
    font-weight: 500;
}

h2 {
    color: #fff;
    font-size: 19px;
    font-weight: 400;
}

.home__right1 {
    flex: 0.4;



}

.home__right1 img {
    width: 450px;
    height: 450px;


}

.home__container2 {
    background-color: #eff1f6;
    height: 580px;
    display: flex;
    padding-top: 3rem;

}

.home__content .home__left .black__text {
    color: #42545b
}

.home__content .home__left .black__text_desc {
    color: #42545b;
    font-size: 19px;
    margin-left: -18px;
}

.home__left .white__text_desc {
    margin-left: -18px;
}

.home__container2 .home__inner .home__content .home__right2 {
    background-color: #bcc2be;
    flex: 0.4
}

.home__left h1 {
    margin-left: -20px;

}

.vedio__container{

background-color: #eff1f5;
    display: flex;
    height: 580px;
}
.vedio__inner {
    margin: auto;
    width: 80%;
   

}
@media only screen and (max-width: 2300px) {
    .home__content .home__left .black__text_desc {
        color: #42545b;
        font-size: 19px;
        margin-left: -18px;
    }

    .home__left .white__text_desc {
        margin-left: -18px;
    }


}

@media only screen and (max-width: 1201px) {
    .home__right1 img {
        width: 450px;
        height: 450px;


    }


}

@media only screen and (max-width: 800px) {
    .home__right1 img {
        width: 450px;
        height: 450px;

    }



}

@media only screen and (max-width: 768px) {
    .home__right1 img {
        width: 450px;
        height: 450px;
        padding-bottom: 20px;

    }

    .home__content {
        display: flex;

        flex-direction: column;

        justify-content: center;

    }

    .home__right1,
    .home__right2 {
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 480px) {
    .home__right1 img {
        width: 409px;
        height: 420px;

    }

    .home__content {
        display: flex;
        flex-direction: column;
        justify-self: center;
        justify-content: center;


    }


}