.col3 {
    width: 210px;
}

.col4 {
    width: 500px;
}

@media only screen and (max-width: 745px) {
    .small_screen_text {
        font-size: 12px;
    }
    .col4 {
        width: 260px;
    }
}