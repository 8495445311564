.header__container {
    height: 70px;
    display: flex;
    background-color: #fff;
    width: 100%;




}

.header__inner {
    width: 80%;
    margin: auto;


}

.header__logo {

    height: 2rem;

}

@media only screen and (max-width: 480px) {
    .header__logo {

        height: 1.3rem;


    }

}

@media only screen and (max-width: 768px) {
    .header__logo {

        height: 1.5rem;

    }



}