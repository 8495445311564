.layout {
    background-color: rgb(239, 240, 240);
    height: calc(97vh - 60px);
    /* height: 93vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
}

.sidebar {
    display: none;
}

.login {
    background-color: white;
    border-radius: 15px;
    width: 80vw;
    height: 40vh;
    max-width: 800px;
    min-height: 310px;
    max-height: 500px;
    padding: 0 4%;

}

.form {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.input {
    border-radius: 10px;
    border: 2px solid #313149;
}
.ant-pswd {
    border-radius: 10px;
    border: 2px solid #313149;
}


.item {
    width: 100%;
}