.entry {
    color: #233886;
    background-color: #3C3D3F;
}

#overview-container {
    width: 100%;
    height: fit-content;
    padding-bottom: 2.0em;

    background-clip: padding-box;

    border: 0 solid white;
    border-radius: 15px;
}

.overview-chart {
    width: 90%;
    margin: 30px auto 20px auto;
    justify-content: center;
    background: #FFFFFF;
    border: 0 solid white;
    border-radius: 15px;

    font-size: 20px;
    box-shadow: 0 0 10px #BBB;
}



.overview-content {
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 14px;

    width: 90%;
    margin-right: auto;
    margin-left: auto;
    color: #2f2f2f;

    font-size: 1.2rem;
    /*justify-content: center;*/
}

.overview-content strong {
    font-family: 'Signika', sans-serif;
}

.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.inLayer {
    background-color: white;
    padding: 10px 0;
}