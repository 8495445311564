@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body {
    font-family: 'Noto Sans KR', sans-serif !important;
}

.list_container {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    height: 595px;
    padding: 30px;
}

.title {
    color: #323232;
    font-size: 25px;
    font-weight: bold;
}

.sub_title {
    font-weight: 600;
}

.deleteButton {
    align-items: center;
    background-color: #fe4806;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.button {
    align-items: center;
    background-color: #0A66C2;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.button:hover {
    background-color: #16437E;
    color: #ffffff;
}

.button:active {
    background: #09223b;
    color: rgb(255, 255, 255, .7);
}

.button:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}

.button_cancel {
    align-items: center;
    background-color: #FF5959;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.button_cancel:hover {
    background-color: #9B0000;
    color: #ffffff;
}

.button_cancel:active {
    background: #9B0000;
    color: rgb(255, 255, 255, .7);
}

.button_cancel:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}

.coupon_button {
    align-items: center;
    background-color: #019267;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    width: 120px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    position: absolute;
    left: calc(50% - 60px);
}

.coupon_button:hover {
    background-color: #00694a;
    color: #ffffff;
}

.coupon_button:active {
    background: #00694a;
    color: rgb(255, 255, 255, .7);
}

.coupon_button:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}

.refund_box {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    background-color: #19bc28;
    height: 100px;
    margin: 10px 15px;
    padding: 15px 0;
}

.refund_info {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #b0b1b6;
    margin: 0px 15px;
}

.refund_info_title {
    color: #b0b1b6;
    font-weight: 400;
}

.refund_info_content {
    font-weight: 500;
}

.inquiry_body {
    background-color: white;
    height: 100%;
    padding: 20px;
}

.inquirer_time {
    color: gray;
    font-size: 12px;
}

.inquirer_icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #f95ca1;
    border-radius: 100px;
}

.inquirer_name {
    display: inline-block;
    height: 50px;
    margin-left: 10px;
}

.inquiry_body_title {
    color: #171d4b;
    font-size: 20px;
    font-weight: bold;
    margin-left: 50px;
}

.inquiry_body_question {
    color: #383d53;
    font-weight: 500;
    margin-top: 20px;
}

.inquiry_body_answer {
    border: none;
    background-color: #F5F5F5;
    border-radius: 10px;
    height: 300px !important;
    resize: none;
    outline: none;
    white-space: pre-wrap;
}

.inquiry_body_answer:focus {
    outline: none !important;
    border: none;
}


.answer_icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #7c71f9;
    border-radius: 100px;
    margin-top: 40px;
}

.code_button_container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.code_button {
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #066bff;
    border: 0;
    border-radius: 10px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.code_container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.code.hidden {
    opacity: 0;
    transform: translateY(-25px);
    transition: all 1s;
}

.code {
    background-color: #282c34;
    color: white;
    font-weight: bold;
    padding: 10px 30px;
    width: 450px;
    border-radius: 20px;
    font-size: 30px;
    letter-spacing: 3px;
    display: flex;
    justify-content: space-between;
    transition: all 1s;
    opacity: 1;
    transform: translateY(0px);
}

.clipboard {
    font-size: 20px;
    color: white;
    transition: all 0.3s;
}

.clipboard.copied {
    font-size: 20px;
    color: red;
    transition: all 1s;
}


.p-5 {
    padding: 1.5rem !important;
}

.auto_button {
    align-items: center;
    background-color: #019267;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    width: 120px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.battle_button {
    align-items: center;
    background-color: #019267;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    width: 150px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    position: absolute;
    left: calc(50% - 60px);
}

@media (min-width: 992px) {
    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}


@media (min-width: 1205px) {
    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666666%;
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media only screen and (min-width: 1950px) {
    .infoHeader {
        height: 200px;
        width: 400px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .infoHeader .top {
        text-align: center;
        font-size: 100px;
        font-weight: bold;
    }

    .infoHeader .bottom {
        text-align: center;
        font-weight: bold;
    }

    .infoButtonHeader {
        height: 200px;
        width: 400px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .adminButton {
        width: 60%;
        text-align: center;
        height: 50px;
        margin: 5px 0 5px 0;
        line-height: 50px;
        border-radius: 30px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }

    .infoTitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .infoBody {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        padding: 20px;
    }

    .functionContainer1 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        margin-top: 70px;
    }

    .functionContainer2 {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        padding: 70px;
        margin-top: 70px;
    }

    .functionContainer3 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
    }
}

@media only screen and (max-width: 1950px) {
    .infoHeader {
        height: 150px;
        width: 300px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .infoHeader .top {
        text-align: center;
        font-size: 70px;
        font-weight: bold;
    }

    .infoHeader .bottom {
        text-align: center;
        font-weight: bold;
    }

    .infoButtonHeader {
        height: 150px;
        width: 300px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .adminButton {
        width: 50%;
        text-align: center;
        height: 40px;
        margin: 3px 0 3px 0;
        line-height: 40px;
        border-radius: 30px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }

    .infoTitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .infoBody {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .functionContainer1 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        margin-top: 70px;
    }

    .functionContainer2 {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        padding: 70px;
        margin-top: 70px;
    }

    .functionContainer3 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
    }
}

@media only screen and (max-width: 1460px) {
    .infoHeader {
        height: 100px;
        width: 200px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .infoHeader .top {
        text-align: center;
        font-size: 45px;
        font-weight: bold;
    }

    .infoHeader .bottom {
        text-align: center;
        font-weight: bold;
    }

    .infoButtonHeader {
        height: 100px;
        width: 200px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .adminButton {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 50px;
        border-radius: 13px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }

    .infoTitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .infoBody {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .functionContainer1 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        margin-top: 70px;
    }

    .functionContainer2 {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        padding: 50px;
        margin-top: 70px;
    }

    .functionContainer3 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
    }
}

@media only screen and (max-width: 1205px) {
    .functionContainer1 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .functionContainer2 {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        padding: 70px;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .functionContainer3 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
    }
}

@media only screen and (max-width: 680px) {
    .infoHeader {
        height: 80px;
        width: 160px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
    }

    .infoHeader .top {
        text-align: center;
        font-size: 35px;
        font-weight: bold;
    }

    .infoHeader .bottom {
        text-align: center;
        font-weight: bold;
    }

    .infoButtonHeader {
        height: 80px;
        width: 160px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .adminButton {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 15px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
        padding: 8px 5px 0 5px;
    }

    .infoTitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .infoBody {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .functionContainer1 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .functionContainer2 {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        padding: 80px;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .functionContainer2 .adminButton {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 30px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
        padding: 8px 5px 0 5px;
    }

    .functionContainer3 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
    }
}

@media only screen and (max-width: 576px) {
    .infoHeader {
        height: 100px;
        width: 100%;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .infoHeader .top {
        text-align: center;
        padding: 0px;
        font-size: 70px;
        font-weight: bold;
        margin-left: 80px;
        line-height: 88px;
    }

    .infoHeader .bottom {
        text-align: center;
        font-weight: bold;
        font-size: 50px;
        padding: 10px 0 10px 0;
        margin-right: 80px;
    }

    .infoButtonHeader {
        height: 100px;
        width: 100%;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .adminButton {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 30px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
        padding: 8px 5px 0 5px;
    }

    .infoTitle {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .infoBody {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .functionContainer1 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .functionContainer2 {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
        padding: 60px;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .functionContainer3 {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        transform: translateY(25px);
        animation: push 1s;
    }
}

@keyframes push {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    100% {
        opacity: 1;
        transform: translateY(25px);
    }
}

.signUpContainer {
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: 10px;
    padding-bottom: 30px;
}

.xpercent {
    font-family: "Poppins", sans-serif;
    color: #333;
    padding-top: 10px;
    padding-left: 50px;
    display: flex;
    align-items: center;
}

.xpercent img {
    height: 30px;
    width: 30px;
}

.xpercent span {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .4em;
    cursor: pointer;
}

.signUpTitle {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 30px;
    font-weight: 900;
    color: #333;
    letter-spacing: 0.2em;
    padding-left: 50px;
    margin-bottom: 30px;
}

.selectModel {
    font-family: 'Noto Sans KR', sans-serif;
}

.signUpInput {
    width: 100%;
    padding: 10px 20px 10px 20px;
    font-weight: 600;
}

.signUpInput::placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500
}

.signUpInput input::placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500
}

.signUpButton {
    background-color: #0041ed;
    font-family: 'Noto Sans KR', sans-serif;
    border: none;
    padding: 10px 20px;
    margin: 0 50px;
    text-align: center;
    color: white;
    width: 100%
}

@media only screen and (max-width: 1516px) {
    .xpercent img {
        height: 25px;
        width: 25px;
    }

    .xpercent span {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }
}

/* @media only screen and (max-width: 1140px) {
    .xpercent img {
        height: 20px;
        width: 20px;
    }
    
    .xpercent span {
        font-size: 25px; 
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }
} */

@media only screen and (max-width: 1205px) {
    .xpercent img {
        height: 30px;
        width: 30px;
    }

    .xpercent span {
        font-size: 40px;
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }
}

@media only screen and (max-width: 480px) {
    .xpercent img {
        height: 20px;
        width: 20px;
    }

    .xpercent span {
        font-size: 25px;
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }

    .infoHeader .top {
        text-align: center;
        padding: 0px;
        font-size: 60px;
        font-weight: bold;
        margin-left: 80px;
        line-height: 88px;
    }

    .infoHeader .bottom {
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        padding: 10px 0 10px 0;
        margin-right: 80px;
    }
}

.date_container {
    background-color: #eaf9f1;
    padding: 7px 10px;
    border-radius: 10px;
}

.active_date {
    font-weight: bold;
    color: #46c795;
    font-size: 15px;
    margin: 0px 3px;
}

.inactive_date {
    color: #b2beba;
    font-weight: bold;
    font-size: 15px;
    margin: 0px 3px;
}

.change_date {
    cursor: pointer
}

input[type="checkbox"] {
    visibility: hidden;
    display: none;
}

input[type="checkbox"]:checked+.selectModel {
    /* transform: rotate(360deg); */
    background-color: #000;
}

input[type="checkbox"]:checked+.selectModel:before {
    transform: translateX(30px);
    background-color: #fff;
}

.selectModel {
    display: flex;
    width: 60px;
    height: 30px;
    border: 3px solid;
    border-radius: 99em;
    position: relative;
    transition: transform 0.75s ease-in-out;
    transform-origin: 50% 50%;
    cursor: pointer;
}

.selectModel:before {
    transition: transform 0.75s ease;
    /* transition-delay: 0.5s; */
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #000;
    border-radius: 50%;
    top: 5px;
    left: 5px;
}

.toggleOff:before {
    content: "OFF";
    left: 24px;
    position: absolute;
    top: 2px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 600;
    opacity: 1;
    transition: opacity 0.75s ease;
}

.toggleOn:after {
    content: "ON";
    right: 28px;
    position: absolute;
    color: white;
    top: 2px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 600;
    opacity: 0;
    transition: opacity 0.75s ease;
}

input[type="checkbox"]:checked+.selectModel .toggleOn:after {
    opacity: 1;
}

input[type="checkbox"]:checked+.selectModel .toggleOff:before {
    opacity: 0;
}

.selectSector label {
    display: inline-block;
    background-color: white;
    color: #adadad;
    border-radius: 10px;
    white-space: nowrap;
    margin: 3px 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s;
    padding: 2px 6px;
    cursor: pointer;
    margin: 1px;
}

.selectSector input[type="checkbox"]:checked+.kospi {
    background-color: #ff533e;
    color: #fff;
    transition: all .2s;
}

.selectSector input[type="checkbox"]:checked+.kosdaq {
    background-color: #ff533e;
    color: #fff;
    transition: all .2s;
}

.selectSector input[type="checkbox"] {
    display: absolute;
}

.selectSector input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.selectSector input[type="checkbox"]:focus+label {
    border: 2px solid #e9a1ff;
}

.inputNotChanged input {
    color: black !important;
}

.inputChanged input {
    color: blue !important;
}