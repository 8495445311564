.vectorBackground {
    background-image: url('../../images/vectorBackground.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 300px;
    width: 30%;
    border-radius: 10px;
}

.vectorContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.select-form {
    margin: 10px auto;
    text-align: center;
}

.date-select {
    background-color: rgba(0, 0, 0, 0);
    color: #2f2f2f;
    border-radius: 15px;
    height: 30px;
}

.date {
    width: 100%;
    text-align: center;
    font-size: 25px;
    vertical-align: middle;

    margin-top: 10px;
}

.notification {
    margin: 10px auto;
    font-size: 12px;
}

.smallBox {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 3px 6px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.vectorIcon {
    font-size: 50px !important;
    color: white;
}

.vectorPerc {
    font-size: 80px;
    font-weight: bold;
    color: white;
}

.vectorTitle {
    color: #b9bff4;
    font-size: 30px;
}

@media only screen and (max-width: 600px) {
    .vectorContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
    }

    .vectorBackground {
        height: 200px;
        width: 90%;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    .vectorIcon {
        font-size: 30px !important;
        color: white;
    }

    .vectorPerc {
        font-size: 50px;
        font-weight: bold;
        color: white;
    }

    .vectorTitle {
        color: #b9bff4;
        font-size: 20px;
    }
}