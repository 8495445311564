@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body {
    font-family: 'Noto Sans KR', sans-serif;
}

.payment_form_container {
    width: 500px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f7fa;
}

.go_to_container {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.go_to_icon:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}

.icon {
    font-size: 50px; 
    margin-right: 20px;
    color: #52c41a;
}

.text {
    font-size: 30px;
}

@media (max-width: 800px) {
    .payment_form_container {
        width: 90%;
        margin: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-left: auto;
        margin-right: auto;
        background-color: #f6f7fa;
    }

    .icon {
        font-size: 40px;
    }

    .text {
        font-size: 22px;
    }
}