.appstore__container {

    background-color: #eff1f6;
    padding-top: 3rem;
    display: flex;


}

.appstore__inner {
    margin: 0 auto;
    width: 80%;


}

.appstore__left .header h1 {
    margin-left: -20px;



}

.appstore__content {
    display: flex;
    align-items: flex-start;

}

.appstore__left {
    flex: 0.6;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.appstore__left .header {
    margin-left: 20px
}

.appstore__left .header>h1 {
    color: #343434
}

.desc>p {
    color: #5f5f60;
    font-size: 19px;
}

.tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;

}

.tag__box {
    margin-right: 10px;
    padding: 2px 10px;
    border-radius: 1rem;
    border: 1px solid #3e4a58;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}

.buttons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 300px;

}

.btn {
    padding: 1rem 0.2rem;
    cursor: pointer;
}

.appstore__right {
    flex: 0.4;

}

.appstore__right img {
    width: 580px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

}

@media only screen and (min-width: 2302px) and (max-width: 3000px) {
    .appstore__left .header h1 {
        margin-left: -20px;



    }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appstore__container {
        background-color: #eff1f6;
        display: flex;

    }

    .appstore__left {

        margin-top: 0rem;
    }

    .appstore__left .header>h1 {
        color: #343434;
        font-size: 22px;
    }

    .desc>p {
        color: #5f5f60;
        font-size: 17px;
    }

    .btn {
        margin: 1rem 0.3rem;
        cursor: pointer;

    }

    .appstore__right {
        position: relative;
        bottom: 0;

        display: flex;
        flex-direction: flex-end;
        align-items: flex-end;

    }

    .appstore__right img {
        width: 390px;
        height: 390px;
        /* bottom:0 */

    }


    .appstore__right {
        /* background-color: yellow; */
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .appstore__container {

        background-color: #eff1f6;
        display: flex;

    }

    .appstore__left {

        margin-top: 0rem;
    }

    .appstore__left .header>h1 {
        color: #343434;
        font-size: 22px;
    }

    .desc>p {
        color: #5f5f60;
        font-size: 17px;
    }

    .btn {
        margin: 1rem 0.3rem;
        cursor: pointer;

    }

    .appstore__right img {
        width: 420px;
        height: 310px;

    }

    .appstore__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .appstore__right {
        /* background-color: yellow; */
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 480px) {
    .appstore__container {
        background-color: #eff1f6;
        display: flex;
        /* height: 690px; */
    }

    .appstore__left {

        margin-top: 0rem;
    }

    .appstore__left .header>h1 {
        color: #343434;
        font-size: 22px;
    }

    .desc>p {
        color: #5f5f60;
        font-size: 17px;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 200px;

    }

    .btn {
        margin: 1rem 0.3rem;
        cursor: pointer;

    }

    .appstore__content {
        display: flex;

        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .appstore__right img {
        width: 370px;
        height: 300px;

    }


}