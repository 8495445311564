.about {
    /* padding-bottom: 10px; */
}

.contentSection {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.container {
    background-color: #F6EFE8;
    color: black;
    font-family: 'Nanum Gothic', sans-serif;
    text-align: center;
    font-size: 14px;
}

.last {
    margin-bottom: 30px;
}

.left_hr {
    border-top-style: solid;
    border-top-color: white;
    border-top-width: 1px;
    width: 20%;
    margin: 15px 0;
}

.right_hr {
    border-top-style: solid;
    border-top-color: black;
    border-top-width: 1px;
    width: 20%;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: auto;
}

.center_hr {
    border-top-style: solid;
    border-top-color: white;
    border-top-width: 1px;
    width: 20%;
    margin: 15px auto;
}

.about_1 {
    width: 100%;
    background-color: white;
    color: #000000;
    padding: 80px 7%;
}

.about_2 {
    width: 100%;
    background-color: #DEDEDE;
    color: black;
    padding: 80px 7%;

}


.about_3 {
    width: 100%;
    background: #990000;
    color: white;
    padding: 80px 7%;

}

.about_5 {
    width: 100%;
    background: linear-gradient(20deg, rgba(96, 189, 140, 1) 0%, rgba(75, 172, 206, 1) 70%, rgba(75, 172, 206, 1) 100%);
    color: white;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 40px;
}

.about_slider {
    width: 100%;
    height: 750px;
    background: linear-gradient(20deg, rgba(96, 189, 140, 1) 0%, rgba(75, 172, 206, 1) 70%, rgba(75, 172, 206, 1) 100%);
    color: white;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 40px;
}

.about_left_topic {
    padding-top: 30px;
    font-size: 25px;
    text-align: left;
    font-weight: bold;
}

.about_right_topic {
    padding-top: 30px;
    font-size: 25px;
    text-align: right;
}

.about_center_topic {
    padding-top: 30px;
    font-size: 25px;
    text-align: center;
}

.about_content {
    text-align: left;
    word-break: keep-all;
    line-height: 25px;
    padding-bottom: 30px;
}

.about_center_content {
    text-align: center;
    word-break: keep-all;
    line-height: 25px;
    padding-bottom: 30px;
}

.about_home {
    width: 60%;
    font-size: 18px;
    color: #FFFFFF;

    background-color: #990000;
    border-style: solid;

    border-width: 1px;
    border-color: #990000;
    border-radius: 10px;

    margin-left: auto;
    margin-right: auto;

    padding-top: 5px;
    padding-bottom: 5px;
}

.footer {
    width: 100%;
    background: #2b2b2b;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #b1b1b1;
}

.footer a:link {
    color: #b1b1b1;
}

.footer a:visited {
    color: #b1b1b1;
}

.footer a:focus {
    color: #b1b1b1;
}

.footer a:hover {
    color: #b1b1b1;
}

.footer_container {
    font-size: 13px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.footer_company {
    text-align: left;
    font-size: 11px;
}

.footer_company_name {
    font-weight: bold;
}

.footer_company_detail {
    margin-top: 5px;
}

.footer_hr {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 30%;
    border-top-style: solid;
    border-top-color: white;
}

.incDetail {
    color: #b1b1b1;
    text-align: left;


}

.incName {
    font-size: 24px;
    font-weight: bold;
    color: #b1b1b1;
    padding-bottom: 2rem;

}

.incHr {
    border-top: 1px dotted #f5f7f7;
}

.red_bold {
    color: #990000;
    font-size: 20px;
    font-weight: bold
}