.select-form {
    margin: 10px auto;
    text-align: center;
}

.date-select {
    background-color: rgba(0, 0, 0, 0);
    color: #2f2f2f;
    border-radius: 15px;
    height: 30px;
}

.date {
    width: 100%;
    text-align: center;
    font-size: 25px;
    vertical-align: middle;

    margin-top: 10px;
}

.notification {
    margin: 10px auto;
    font-size: 12px;
}

.isAdministrativeNotify {
    justify-content: center;
    border: 1px solid;
    width: 30px;
    height: 30px;
    font-size: 12px;
    padding: 2px;
    border-radius: 30px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
