@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

.leftdiv {
    float: left;
    width: 500px;

}

.rightdiv {
    float: left;
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
}

/*div {*/
/*    color: black;*/
/*    background-color: rgb(223, 217, 217);*/
/*    height: 50px;*/
/*    font-size: 12px;*/
/*}*/

.btn0 {
    margin-right: 50px;
    width: 300px;
    height: 40px;
}

.btn1 {
    margin-left: 50px;
    width: 300px;
    height: 40px;
}

.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 20px;
    border-radius: 3px;
}

.p_header {
    padding: 10px 0 0 0;
    margin: 0px 5px
}

.section {
    margin: 30px;
}

.content {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.main_divider {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}

.content_divider {
    margin: 5px 0;
}

.free_table {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 15px 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.button {
    width: 100%;
    height: 40px;
    /* margin: 5px; */
}

.header {
    background-color: #990000;
    padding: 5px 15px;
    color: white;
    border-radius: 20px;
    font-weight: 600;
}

.payment_body {
    height: 1223px;
    background: linear-gradient(
        to bottom,
        #e6ebef 0%,
        #e6ebef 50%,
        #990000 50%,
        #990000 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment_container {
    height: 1000px;
    width: 1400px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.payment_container .payment_inner_container {
    padding: 0px 200px;
}

.payment_title {
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    color: #990000;
    margin-top: 30px;
    font-family: 'Noto Sans KR', sans-serif;
}

.payment_box {
    height: 500px;
    border: 1px solid #d8dfeb;
    border-radius: 10px;
}

.payment_box div {
    text-align: center;
}

.payment_sub_type {
    font-size: 30px;
    color: #d9dde8;
    font-weight: 700;
}

.payment_price {
    font-size: 40px;
    font-weight: 900;
    color: #90a3bf;
}

.payment_info {
    display: flex; 
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 10px;
}

.payment_info_title {
    color: #90a3bf;
    font-weight: 700;
}

.payment_info_content {
    color: #90a3bf;
    font-weight: 500;
}

.select_button {
    width: 160px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #d8dfeb;
    font-weight: 600;
    color: #90a3bf;
    border-radius: 10px;
    font-size: 15px;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 80px);
    cursor: pointer;
}

.rate_container {
    margin: 30px 0px
}

.rate {
    font-size: 40px;
    color: #ff9933
}

.payment_memType_container {
    display: flex;
}

.payment_price_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 1500px)
{
    .payment_body {
        height: 1033px;
    }

    .payment_container {
        height: 1000px;
        width: 1000px;
    }
    
    .payment_container .payment_inner_container {
        padding: 0px 100px;
    }

    .header {
        padding: 5px 8px;
    }
}

@media only screen and (max-width: 992px)
{
    .payment_body {
        height: auto;
        background: white;
    }

    .payment_container {
        height: auto;
        width: 1000px;
        border-radius: 0px;
    }

    .payment_container .payment_inner_container {
        padding: 0px 20px;
    }

    .payment_box {
        margin-bottom: 20px;
        height: auto;
    }

    .select_button {
        position: relative;
        bottom: auto;
        left: auto;
        margin-bottom: 20px;
    }

    .payment_sub_type {
        font-size: 20px;
    }
    
    .payment_price {
        font-size: 30px;
        font-weight: 900;
        color: #90a3bf;
    }

    .rate_container {
        margin: 10px 0px
    }

    .rate {
        font-size: 30px;
        color: #ff9933;
    }

    .payment_radio_header {
        margin-right: auto;
    }
    
}

@media only screen and (max-width: 430px) {
    .payment_memType_container {
        flex-direction: column;
    }

    .header {
        margin-right: auto
    }

    .payment_price_container {
        flex-direction: column;
        align-items: start;
    }
}