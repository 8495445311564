.tip_home__container1 {
    background-color:#263670 ;
    /* height: 600px; */
    display: flex;
    /* padding-top: 2rem; */
    z-index: 9999;
}
.tip_home__inner {
    margin: auto;
    width: 80%;

}
.tip_home__content {
    display: flex;

    align-items: center;
     



}
.tip_home__container1 .tip_home__inner .tip_home__content .tip_home__left {
    flex: 0.6;

    flex-wrap: wrap;



}
.tip_home__left>h1 {
    color: #eff1f6;
    font-weight: 500;
}

.tip_home__left>h2 {
    color: #eff1f6;
    font-size: 19px;
    font-weight: 400;
}

.tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
    flex: 0.4;
/* justify-content: center; */


}

.tip_home__right1 img {
    width: 167px;
    
    margin-top: 22px;
    

}

@media only screen and (max-width: 2900px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
     margin-left: 349px;
  
    
    
    }

    .tip_home__left .white__text_desc {
        margin-left: -18px;
    }
   
   

}

@media only screen and (max-width: 2300px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-left: 349px;
    
       
    }

    .tip_home__left .white__text_desc {
        margin-left: -18px;
    }
   
   

}
@media only screen and (max-width: 1429px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-left: 303px;
    
       
    }

    .tip_home__left .white__text_desc {
        margin-left: -18px;
    }
   
   

}
@media only screen and (max-width: 1329px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-left: 235px;
    
       
    }

    .tip_home__left .white__text_desc {
        margin-left: -18px;
    }
   
   

}

@media only screen and (max-width: 1201px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-left: 139px;
    
       
    }
    .tip_home__right1 img {
        width: 167px;
        margin-top: 27px;
        
    
    }
   


}
@media only screen and (max-width: 1092px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-right: 143px;
    
       
    }
    .tip_home__right1 img {
        width: 167px;
        margin-top: 27px;
        
    
    }
   


}

@media only screen and (max-width: 800px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-left: 0px;
    
       
    }
    .tip_home__right1 img {
        width: 167px;
        margin-top: 27px;
        
    
    }



}

@media only screen and (max-width: 768px) {
    .tip_home__right1 img {
        width: 167px;
        margin-top: 27px;
        
    
    }
      .tip_home__container1 {
      
        padding-top: 1rem;
      
      }
    .tip_home__content {
        display: flex;

        flex-direction: column;

        justify-content: center;

    }

    .tip_home__right1 {
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 480px) {
    .tip_home__right1 img {
        width: 167px;
        margin-top: 27px;
        
    
    }

    .tip_home__content {
        display: flex;
        flex-direction: column;
        justify-self: center;
        justify-content: center;


    }
    .tip_home__container1 {
      
        padding-top: 1rem;
      
    }
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-right: 0px;
    }


}