@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body {
    font-family: 'Noto Sans KR', sans-serif;
}

.leftdiv {
    float: left;
    width: 500px;

}

.rightdiv {
    float: left;
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
}

/*div {*/
/*    color: black;*/
/*    background-color: rgb(223, 217, 217);*/
/*    height: 50px;*/
/*    font-size: 12px;*/
/*}*/

.btn0 {
    margin-right: 50px;
    width: 300px;
    height: 40px;
}

.btn1 {
    margin-left: 50px;
    width: 300px;
    height: 40px;
}

.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 20px;
    border-radius: 3px;
}

.p_header {
    padding: 10px 0;
}

.section {
    margin: 30px;
}

.content {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.main_divider {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}

.content_divider {
    margin: 5px 0;
}

.free_table {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.button {
    background-color: #EDEDED;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 6px 0;
}

.selectedButton {
    background-color: #990000;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 6px 0;
}

.payment_form_container {
    width: 500px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f7fa;
}

.payment_amount {
    display: flex;
    justify-content: space-between;
    padding: 40px 30px 70px 30px;
    background-color: #12213b;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.payment_amount_tit {
    font-size: 25px;
    color: #ffffff;
    font-weight: 700;
    line-height: 52px;
}

.payment_amount_num {
    font-size: 25px;
    color: #ffffff;
}

/* TOGGLE STYLING */
.toggle {
    padding-bottom: 50px;
    box-sizing: border-box;
    font-size: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
    margin-top: -30px;
}
.toggle input {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
}
.toggle input + label {
    margin: 0;
    padding: 1.25rem 2rem;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border: solid 1px #ddd;
    background-color: #fff;
    font-size: 16px;
    line-height: 140%;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    transition: border-color 0.15s ease-out, color 0.25s ease-out, background-color 0.15s ease-out, box-shadow 0.15s ease-out;
    cursor: pointer;
}
.toggle input + label:first-of-type {
    border-radius: 6px 0 0 6px;
    border-right: none;
}
.toggle input + label:last-of-type {
    border-radius: 0 6px 6px 0;
    border-left: none;
}
.toggle input:hover + label {
    border-color: #ddd;
}
.toggle input:checked + label {
    background-color: #607181;
    color: #fff;
    box-shadow: 0 0 10px rgba(96,113,129, .5);
    border-color: #607181;
}
.toggle input:focus + label {
    outline: 0px transparent;
}
@media (max-width: 800px) {
    .toggle input + label {
        padding: 0.75rem 0.25rem;
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
   }

   .payment_form_container {
    width: 100%;
    margin: 0px;
    border-radius: 0px;
    box-shadow: none;
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f7fa;
    }
    .payment_amount {
        display: flex;
        justify-content: space-between;
        padding: 40px 30px 70px 30px;
        background-color: #181a29;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}