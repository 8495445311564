.body{
  height: 100vh;
}
.App {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 110vh;
    background-color: #f2f2f2;
  }
  .container_app{
  
    background-color: #fff;
    width: 459px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 30px */
  
   
  }
  .title_h1 {
      /* padding-top: 34px; */
  color:#2b53ba;
  text-align: center;
  padding-top: 38px;
  }
  .text_p{
    font-size: 23;
    text-align: center;
    color:#000;
  }
  .total_user_p{
    margin-top: 23px;
  }
  .total_user_app{
    color: #fc8a55;
    font-size: 20;
  }
  .test_btn {
    position: absolute;
    bottom: 20px;
    padding: 10px 100px;
    border: none;
    background-color: #4c8be7;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
  }
  
  .title_h4 {
    width: 78%;
    padding-top: 23px;
  color:#2b53ba;
  text-align: center;
  }
  
  .tip_progress_container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
  }
  
  .tip_progress-bar {
    /* display: flex; */
    width: 70%;
    height: 10px;
    background-color: #ddd;
    /* margin-top: 20px; */
    position: relative;
    align-items: center;
   
  }
  
  .tip_progress {
    height: 100%;
    background-color: #4CAF50;
  }
  
  .screen-number {
    color: #000;
    font-size: 14px;
  }
  
  .close {
  
    padding-top: 10px;
  
    /* position: absolute;
    top: 26px;
    right: 34px; */
    cursor: pointer;
  }
  
  svg {
    display: block;
  }
  .header_container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .inner_content_container{
  
    width: 100%;
    align-items: center;
    
    flex-direction: column;
  
  }
  .header_left_container{
    
  
  }
  .header_h3{
    color: #2b53ba;
   /* padding-left: 37px; */
   font-size: 21px;

    margin-top: 29px;
    margin-left: 33px;
    margin-right: 33px;
    
  }
  .header_h4{
    color: #2b53ba;
   /* padding-left: 37px; */
   font-size: 19px;

    margin-top: 29px;
    margin-left: 33px;
    margin-right: 33px;
    text-align: center;
    
  }
  .content_headers{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 22px;
    /* width: 342px */

  }
  .rsltcontent_headers{
  
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 32px;
    width: 342px
}
  .stock_view{
    display: flex;
    flex-direction:row;
    margin-bottom: 15px;
    justify-content: center;
    padding-left: 7px;
    padding-right: 7px;
    position: relative;
    
  }
 

  .stock_view_wrapper
  {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    width: 347px;
    border: 1px solid #2b53ba;
    /* height: 17vh; */
    height: 16vh;
  }
  .stock_view_wrapper:hover
  {
   
    border: 1px solid #ccc;

  }

  .stock_view_left{
    width: 57%;
    text-align: center;
        padding: 0;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
  }
  .tip_stock_img{

  }
  .tip_stock_name{
    /* padding-top: 22px; */
  }
  .tip_stockimgtext{
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      
    margin-top: -4px;
  }
  .stock_view_right{
    width: 32%;
  }
  .stock_percentage{
    text-align: center;
    padding: 0;
      margin: 0;
  }.loader{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    
  }
  .progress_text{
    color:#3b67aa;
    font-weight: bold;
  }
  .result_container{
    width: 350px;

    background-color: #5d97d9;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 5px;
    /* padding-bottom: 26px; */
    
  }
  .result_header_title{
    color: #fff;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    /* margin: auto; */
    padding: 17px;
  }
  .left_content_header{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .right_content_header{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .result_p_text{
    font-weight: bold;
    font-size: medium;
    width: 160px;
    text-align: center;
  }
  .staticImg{
    margin-top: 15px;
    background-color: #000;
    margin-bottom: 25px;
  }
  
  .result_btn{
    background-color: #4c8be7;
 
    padding: 10px 77px;
    border: none;
    border-radius: 7px;
    color: white;
    font-size: 19px;
    font-weight: bold;
    cursor: pointer;
  }
  .sns_bottom_appstore
  {
  
    align-items: center;
   display: flex;
   justify-content: center;
  }
  .sns_bottom{
    
    margin-top: 20px;
    align-items: center;
    
   display: flex;
   justify-content: center;
   border:1px solid #eee;
   border-radius: 10px;
   padding: 0.5rem 1.8rem;
   width: 345px;

  
  }.sns_title{
    align-items: center;
    width: 250px;
    justify-content: center;
    text-align: center;
    
  }
  .sns_icon_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  /* width: 200px; */
  
  }
  .reload_box{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
   padding:0rem 0.5rem;
    height: 5vh;
    cursor: pointer;
  }
  .reload_box:hover{
    background-color: #f5f5f5;
    color: black;
  }
  .bottom_text{
    font-size: 10px;
    font-weight: "100";
    color:#666667;
    width: 350px;
    text-align: center;
    padding-bottom: 10px;
  
  }
  .red_class{
    color: #e7544c,
  }
  .gray_class{
    color:#000
  }
  .blue_class{
    color:#5d97d9,
  }
  .img_container{
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .tip_askaicontainer{
    margin-top: 20px;
    border:1px solid #5d97d9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1.8rem;
    padding-top: 0.6rem;
    cursor: pointer;
     margin-bottom: 20px;
   

  }
  .tip_askaicontainer:hover{
    border:1px solid #eee;
    background-color: #eee;
   
  }
  .tip_askaicontainer:active{
    border:1px solid #eee;
    background-color: #eee;
  }
.tip_askaishannon_box{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}
.tip_askaishannon_left{
  padding-right: 20px;
}
.tip_askaishannon_left>p{
  color:#5d97d9;
  font-size: 20px;
  font-weight: bold;
  
}
.tip_askaishannon_right>img{
  width:100px ;



}
  @media only screen and  (max-width: 375px) {
    .stock_view {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      justify-content: center;
      padding-left: 7px;
      padding-right: 7px;

  }
  .tip_stockimgtext >img{
    width: 70px;
  }
  .stock_view_wrapper
  {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    width: 347px;
    border: 1px solid #c7c7c7;
    height: 19vh;
    /* height: 14vh; */
  }
  .App {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  .stock_view_wrapper:hover
  {
   
    border: 1px solid #ccc;

  }


  .tip_askaicontainer:hover{

    border:1px solid #eee;
    background-color: #eee;
   
  }
  .tip_askaicontainer:active{
    border:1px solid #eee;
    background-color: #eee;
  }
   } 