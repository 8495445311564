.tip_banner{
  display: flex;
  align-items: center;
 justify-content: center;
  width: 100%;
  height: 80px;
  background-color: #455964;
}
.tip_banner .tip_banner_inner{
 display: flex;
 align-items: center;
justify-content: space-between;
width: 80%;
    margin: auto;
padding-left: 12px;
padding-right: 12px;
}
.tip_banner_wrapper{
  display: flex;

  flex-direction: column;
 
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

[data-aos=fade-left] {
  transform: translate3d(50px,0,0) !important;
}

[data-aos=fade-right] {
  transform: translate3d(-50px,0,0) !important;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0) !important;
}

@media only screen and  (max-width: 465px) {
  .tip_banner{
    display: flex;
    align-items: center;
   justify-content: center;
    width: 100%;
    height: 110px;
    background-color: #455964;
  }}