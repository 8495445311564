
.additional-div {
    background-color: #2f2f2f;
    text-align: center;
    border-radius: 30px;
    margin-top: 15px;
    color: white;
    height: 270px;
    padding: 10px;
}

.total-change-model {
    font-size: 18px;
}

.total-change-content {
    font-family: 'Signika', sans-serif;
    font-size: 20px;
}

.total-change-row {
    margin-top: 10px;
}

.total-change-container {
    width: 100%;
    padding-top: 10px;
}

.total-change-hr {
    border-color: #e7e7e7;
    width: 20%;
    margin: 8px auto;
}

.total-change-rest {
    font-size: 14px;
}


@media only screen and (max-width: 900px)
{
    .additional-div {
        height: 250px;
    }
}

@media(max-width: 450px) {
    .additional-div {
        height: 250px;
    }
}
