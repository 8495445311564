.wrapper {
    max-width: 1024px;
    justify-content: center;
}

.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.title {
    margin: 30px 0;
}

.section {
    margin: 30px;
}

.image img {
    max-width: 70%;
    height: auto;
    display: block;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    margin: 0px auto;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    width: 30%;
    height: 40px;
}