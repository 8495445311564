.wrapper {
    max-width: 1024px;
    justify-content: center;
}

.smallBox {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 3px 6px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.active {
    color: #990000;
    font-weight: bold;
}

.button {
    background-color: #EDEDED;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 0;
}

.selectedButton {
    background-color: #990000;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 0;
}

.disabledButton {
    background-color: #4a4a4a;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 0;
}