.layer {
    background-color: rgb(239, 240, 240);
    height: 100vh;

    justify-content: center;
    align-items: center;

}
.button {
    margin-top: 50px;
    width: 100%;
    height: 40px;
}