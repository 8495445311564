.wrapper {
    max-width: 1024px;
    justify-content: center;
}
.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.inLayer {
    background-color: white;
    padding: 10px 20px;
    margin: 15px;
    border-radius: 3px;
}

.question {
    margin: 15px;
}

.textReply {
    margin: 20px 10px;
}

.field {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}

.field input {
    background-color: #EDEDED !important;
    color: #181818;
}
.field span span {
    color: #030303;
}
.field::placeholder {
    color: grey !important;
}
.field input::placeholder {
    color: grey;
}

.answerBox {
    background-color: white;
    border: thin solid #EDEDED;
    height: 50px;
    color: black;
    /* margin: 10px; */
    width: 100%;
    border-radius: 5px;
}

.button {
    width: 30%;
    height: 40px;
}
