@import url('https://fonts.googleapis.com/css2?family=Signika:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Oswald&display=swap');

.icons {
    width: 28px;
}

.menu_item a {
    color: white;
}
.menu {
    z-index: 9999999;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0;
    /* Works well with 100% width  */
    width: 100vw;
    font-size: 0.9em;
    padding: 0.5em 0.5em;
    align-items: center;
    justify-content: center;
    background-color: #990000;
    box-shadow: 0 0 10px #2f2f2f;
}


.menu_item {
    all: unset;
    flex-grow: 1;
    z-index: 100;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    align-items: center;
    will-change: transform;
    justify-content: center;
    padding: 0.55em 0 0.85em;
    transition: transform 3ms;
    border: none;
    outline: none;
}

.menu_item::before {
    content: "";
    z-index: -1;
    width: 4.7em;
    height: 4.7em;
    border-radius: 50%;
    position: absolute;
    transform: scale(0);
    transition: background-color 0.7s, transform 2ms;
}


.menu_item.active {
    transform: translate3d(0, -.8em, 0);
}


.menu_item.active::before {
    transform: scale(1);
    background-color: #F7A73B
}


.icon {
    width: 2.6em;
    height: 2.6em;
    stroke: white;
    fill: transparent;
    stroke-width: 1pt;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 400;

}

.menu_item.active .icon {
    animation: strok 1.5s reverse;
}

@keyframes strok {
    100% {
        stroke-dashoffset: 400;
    }
}

.menu_border {
    bottom: 99%;
    width: 10.9em;
    height: 1.8em;
    position: absolute;
    clip-path: url(#menu);
    will-change: transform;
    background-color: #990000;
    border: 0 solid transparent;
    transition: transform 1s;
}


.svg_container {
    width: 0;
    height: 0;
    transform: translateX(20);
}

.st0{fill:#FFFFFF;}