.video_containter{
   
    flex-grow: 1; 
   display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 580px;
    width: "100%";
    background-color: #eff1f5;
}

@media only screen and (min-width: 2000px) {
    .video_containter .video_outer_wrapper{
        
        width:1297px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        

    }
    /* .video_wrapper video{
        max-width: 634px;
        min-width: 600px;   
    } */

   
  }
@media only screen and (max-width: 2000px) {
    .video_containter .video_outer_wrapper{
        
        width:1297px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      

    }
    /* .video_wrapper video{
        max-width: 634px;
        min-width: 600px;   
    } */
  }

  @media only screen and (max-width: 1450px) {
    .video_containter .video_outer_wrapper{
        
        width:1297px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    

    }
    /* .video_wrapper video{
        max-width: 634px;
        min-width: 600px;   
    } */
  }
  @media only screen and (max-width: 1315px) {
    .video_containter .video_outer_wrapper{
        
        width:1187px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    

    }
    /* .video_wrapper video{
        max-width: 578px;
        min-width: 578px;   
    } */
  }

  /* @media only screen and (max-width: 1283px) {
    .video_containter .video_outer_wrapper{
        
        width:1240px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
       
    }
   .video_wrapper video{
        width: 605px;
        min-width: 600px;   
    }
  } */
  @media only screen and (max-width: 1200px) {
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
       
    }
   /* .video_wrapper video{
        width: 555px;
        min-width: 400px;   
    } */
  }
  @media only screen and (max-width: 1000px) {
   
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
       
    }
   .video_wrapper video{
        width: 857px;
        min-width: 800px;   
    }
  }
  @media only screen and (max-width: 820px) {
    .video_containter .video_outer_wrapper{
        
        width:"98%" ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
       
    }
   .video_wrapper video{
        width: 759px;
        min-width: 700px;   
    }
  }

  /* @media only screen and (max-width: 800px) {
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
       
    }
   .video_wrapper video{
        width: 370px;
        min-width: 300px;   
    }
  } */
  @media only screen and (max-width: 780px) {
    /* .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
       
    } */
    .video_containter{
     width:100% ;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
   .video_wrapper video{
       width: 709px;
        min-width: 700px;    
    }
  }
  @media only screen and (max-width: 672px) {
  
    .video_containter {
        
        width:100% ;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
       
    }
  .video_containter .video_wrapper > video{
    width: 600px;
    min-width: 590px;  
    }
  }
  @media only screen and (max-width: 600px) {
 .video_containter{
  align-items: center;
  width: 100%;
  height:100%;
 }
   .video_containter .video_wrapper{
    
width: 95%;
   }
   .video_containter .video_wrapper > video{
    width: 100%;
    min-width: 190px;  
    }
    
  }

 