@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Signika:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.deep_bg {
  background-color: #990000 !important;
}
.ant-modal-content {
  background-color: #EDEDED!important;
}
.ant-modal-body {
  padding: 0px!important;
}

.ant-modal-content {
  border-radius: 10px !important; 
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-right: 15px;
  margin-bottom: 15px;
}

/* Admin page create coupon css */
.create_coupon .ant-modal-content {
  background-color: white !important;
}

.create_coupon .ant-form-item-label label {
  font-weight: bold;
}

.create_coupon .ant-radio-group {
  width: 100%;
  text-align: center;
}

.create_coupon .ant-radio-group label {
  width: 45%;
  text-align: center;
  padding: 20px;
  font-size: 15px;
  line-height: 0px;
  margin: 5px;
  font-weight: bold;
}

.create_coupon .ant-checkbox-group {
  width: 100%;
  text-align: center;
}

.create_coupon #coupon_use_membership label {
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 45%;
  text-align: center;
}

.create_coupon #coupon_use_payment_month label {
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 45%;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 5px;
}

.create_coupon .ant-checkbox-group .ant-checkbox-wrapper-checked {
  border-color: #096dd9 !important;
}

.create_coupon .ant-picker-range {
  border-radius: 10px;
}

.create_coupon .ant-picker-range #coupon_use_period_date {
  padding: 5px;
}
/* Admin page create coupon css end */

/* Payment page payment and coupon use css */
.payment_radio {
  display: flex;
  align-items: center;
}

.payment_radio .ant-radio-group {
  width: 90%;
  text-align: center;
}

.payment_radio .ant-radio-group label {
  text-align: center;
  padding: 20px;
  font-size: 15px;
  line-height: 0px;
  margin: 5px;
  font-weight: bold;
  width: 23%;
}

.payment_coupon_code_input input {
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.payment_collapse .ant-collapse-header {
  background-color: #6FDFDF;
  width: 100%;
  border-radius: 5px !important;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  justify-content: center;
  color: 'white'
}

@media only screen and (max-width: 992px) {
  .payment_radio {
    flex-direction: column;
  }

  .payment_radio .ant-radio-group {
    margin-top: 20px;
  }

  .payment_radio .ant-radio-group label {
    width: 45%;
  }
}

@media only screen and (max-width: 430px) {
  .payment_radio .ant-radio-group label {
    text-align: center;
    padding: 20px;
    font-size: 15px;
    line-height: 0px;
    margin: 5px;
    font-weight: bold;
    width: 100%;
  }
}

/* Payment page payment and coupon use css end */

/* Profile */
.payment_info .btn:hover {
  color: white !important;
}

.update_info .btn:hover {
  color: white !important;
}

.else_info .btn:hover {
  color: white !important;
}
/* Profile page end */

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333 !important;
  align-items: center;
  display: flex;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff !important;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

/* Admin page create battle css */
.create_battle .ant-modal-content {
  background-color: white !important;
}
/* Admin page create battle css end */
.app-wrapper {
    padding-bottom: 63px;
    height: 100vh
}

.HeaderUnsigned_col3__2CqAm {
    width: 210px;
}

.HeaderUnsigned_col4__2AZcX {
    width: 500px;
}

@media only screen and (max-width: 745px) {
    .HeaderUnsigned_small_screen_text__nXjAg {
        font-size: 12px;
    }
    .HeaderUnsigned_col4__2AZcX {
        width: 260px;
    }
}
.About_about__1o2da {
    /* padding-bottom: 10px; */
}

.About_contentSection__3wCTb {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.About_container__1Mdjj {
    background-color: #F6EFE8;
    color: black;
    font-family: 'Nanum Gothic', sans-serif;
    text-align: center;
    font-size: 14px;
}

.About_last__2zeuP {
    margin-bottom: 30px;
}

.About_left_hr__MNeEP {
    border-top-style: solid;
    border-top-color: white;
    border-top-width: 1px;
    width: 20%;
    margin: 15px 0;
}

.About_right_hr__2RVSj {
    border-top-style: solid;
    border-top-color: black;
    border-top-width: 1px;
    width: 20%;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: auto;
}

.About_center_hr__3jI1G {
    border-top-style: solid;
    border-top-color: white;
    border-top-width: 1px;
    width: 20%;
    margin: 15px auto;
}

.About_about_1__1yyF9 {
    width: 100%;
    background-color: white;
    color: #000000;
    padding: 80px 7%;
}

.About_about_2__2wU4D {
    width: 100%;
    background-color: #DEDEDE;
    color: black;
    padding: 80px 7%;

}


.About_about_3__2-2dW {
    width: 100%;
    background: #990000;
    color: white;
    padding: 80px 7%;

}

.About_about_5__uBwCk {
    width: 100%;
    background: linear-gradient(20deg, rgba(96, 189, 140, 1) 0%, rgba(75, 172, 206, 1) 70%, rgba(75, 172, 206, 1) 100%);
    color: white;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 40px;
}

.About_about_slider__2jO3D {
    width: 100%;
    height: 750px;
    background: linear-gradient(20deg, rgba(96, 189, 140, 1) 0%, rgba(75, 172, 206, 1) 70%, rgba(75, 172, 206, 1) 100%);
    color: white;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 40px;
}

.About_about_left_topic__8dCSC {
    padding-top: 30px;
    font-size: 25px;
    text-align: left;
    font-weight: bold;
}

.About_about_right_topic__3Qv4U {
    padding-top: 30px;
    font-size: 25px;
    text-align: right;
}

.About_about_center_topic__3ogJ7 {
    padding-top: 30px;
    font-size: 25px;
    text-align: center;
}

.About_about_content__UQ7BF {
    text-align: left;
    word-break: keep-all;
    line-height: 25px;
    padding-bottom: 30px;
}

.About_about_center_content__CHmzU {
    text-align: center;
    word-break: keep-all;
    line-height: 25px;
    padding-bottom: 30px;
}

.About_about_home__JBYpr {
    width: 60%;
    font-size: 18px;
    color: #FFFFFF;

    background-color: #990000;
    border-style: solid;

    border-width: 1px;
    border-color: #990000;
    border-radius: 10px;

    margin-left: auto;
    margin-right: auto;

    padding-top: 5px;
    padding-bottom: 5px;
}

.About_footer__2vyAb {
    width: 100%;
    background: #2b2b2b;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #b1b1b1;
}

.About_footer__2vyAb a:link {
    color: #b1b1b1;
}

.About_footer__2vyAb a:visited {
    color: #b1b1b1;
}

.About_footer__2vyAb a:focus {
    color: #b1b1b1;
}

.About_footer__2vyAb a:hover {
    color: #b1b1b1;
}

.About_footer_container__1_1Nv {
    font-size: 13px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.About_footer_company__3ZQax {
    text-align: left;
    font-size: 11px;
}

.About_footer_company_name__2siwW {
    font-weight: bold;
}

.About_footer_company_detail__jDHwf {
    margin-top: 5px;
}

.About_footer_hr__1NT7b {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 30%;
    border-top-style: solid;
    border-top-color: white;
}

.About_incDetail__3nbnz {
    color: #b1b1b1;
    text-align: left;


}

.About_incName__3yiiv {
    font-size: 24px;
    font-weight: bold;
    color: #b1b1b1;
    padding-bottom: 2rem;

}

.About_incHr__2uz5K {
    border-top: 1px dotted #f5f7f7;
}

.About_red_bold__3ZKIz {
    color: #990000;
    font-size: 20px;
    font-weight: bold
}
.tip_banner{
  display: flex;
  align-items: center;
 justify-content: center;
  width: 100%;
  height: 80px;
  background-color: #455964;
}
.tip_banner .tip_banner_inner{
 display: flex;
 align-items: center;
justify-content: space-between;
width: 80%;
    margin: auto;
padding-left: 12px;
padding-right: 12px;
}
.tip_banner_wrapper{
  display: flex;

  flex-direction: column;
 
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

[data-aos=fade-left] {
  -webkit-transform: translate3d(50px,0,0) !important;
          transform: translate3d(50px,0,0) !important;
}

[data-aos=fade-right] {
  -webkit-transform: translate3d(-50px,0,0) !important;
          transform: translate3d(-50px,0,0) !important;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  -webkit-transform: translateZ(0) !important;
          transform: translateZ(0) !important;
}

@media only screen and  (max-width: 465px) {
  .tip_banner{
    display: flex;
    align-items: center;
   justify-content: center;
    width: 100%;
    height: 110px;
    background-color: #455964;
  }}
.header__container {
    height: 70px;
    display: flex;
    background-color: #fff;
    width: 100%;




}

.header__inner {
    width: 80%;
    margin: auto;


}

.header__logo {

    height: 2rem;

}

@media only screen and (max-width: 480px) {
    .header__logo {

        height: 1.3rem;


    }

}

@media only screen and (max-width: 768px) {
    .header__logo {

        height: 1.5rem;

    }



}
.appstore__container {

    background-color: #eff1f6;
    padding-top: 3rem;
    display: flex;


}

.appstore__inner {
    margin: 0 auto;
    width: 80%;


}

.appstore__left .header h1 {
    margin-left: -20px;



}

.appstore__content {
    display: flex;
    align-items: flex-start;

}

.appstore__left {
    flex: 0.6 1;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.appstore__left .header {
    margin-left: 20px
}

.appstore__left .header>h1 {
    color: #343434
}

.desc>p {
    color: #5f5f60;
    font-size: 19px;
}

.tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    grid-row-gap: 8px;
    row-gap: 8px;

}

.tag__box {
    margin-right: 10px;
    padding: 2px 10px;
    border-radius: 1rem;
    border: 1px solid #3e4a58;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}

.buttons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 300px;

}

.btn {
    padding: 1rem 0.2rem;
    cursor: pointer;
}

.appstore__right {
    flex: 0.4 1;

}

.appstore__right img {
    width: 580px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

}

@media only screen and (min-width: 2302px) and (max-width: 3000px) {
    .appstore__left .header h1 {
        margin-left: -20px;



    }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appstore__container {
        background-color: #eff1f6;
        display: flex;

    }

    .appstore__left {

        margin-top: 0rem;
    }

    .appstore__left .header>h1 {
        color: #343434;
        font-size: 22px;
    }

    .desc>p {
        color: #5f5f60;
        font-size: 17px;
    }

    .btn {
        margin: 1rem 0.3rem;
        cursor: pointer;

    }

    .appstore__right {
        position: relative;
        bottom: 0;

        display: flex;
        flex-direction: flex-end;
        align-items: flex-end;

    }

    .appstore__right img {
        width: 390px;
        height: 390px;
        /* bottom:0 */

    }


    .appstore__right {
        /* background-color: yellow; */
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .appstore__container {

        background-color: #eff1f6;
        display: flex;

    }

    .appstore__left {

        margin-top: 0rem;
    }

    .appstore__left .header>h1 {
        color: #343434;
        font-size: 22px;
    }

    .desc>p {
        color: #5f5f60;
        font-size: 17px;
    }

    .btn {
        margin: 1rem 0.3rem;
        cursor: pointer;

    }

    .appstore__right img {
        width: 420px;
        height: 310px;

    }

    .appstore__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .appstore__right {
        /* background-color: yellow; */
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 480px) {
    .appstore__container {
        background-color: #eff1f6;
        display: flex;
        /* height: 690px; */
    }

    .appstore__left {

        margin-top: 0rem;
    }

    .appstore__left .header>h1 {
        color: #343434;
        font-size: 22px;
    }

    .desc>p {
        color: #5f5f60;
        font-size: 17px;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 200px;

    }

    .btn {
        margin: 1rem 0.3rem;
        cursor: pointer;

    }

    .appstore__content {
        display: flex;

        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .appstore__right img {
        width: 370px;
        height: 300px;

    }


}
.home__container1 {
    background-color: #455964;
    height: 580px;
    display: flex;
    padding-top: 3rem;
    z-index: 9999;
}

.home__inner {
    margin: auto;
    width: 80%;

}

.home__content {
    display: flex;

    align-items: center;
    /* width: 300px; */

    /* flex:1 */


}

.home__content .home__left {
    flex: 0.6 1;

    flex-wrap: wrap;



}

h1 {
    color: #fff;
    font-weight: 500;
}

h2 {
    color: #fff;
    font-size: 19px;
    font-weight: 400;
}

.home__right1 {
    flex: 0.4 1;



}

.home__right1 img {
    width: 450px;
    height: 450px;


}

.home__container2 {
    background-color: #eff1f6;
    height: 580px;
    display: flex;
    padding-top: 3rem;

}

.home__content .home__left .black__text {
    color: #42545b
}

.home__content .home__left .black__text_desc {
    color: #42545b;
    font-size: 19px;
    margin-left: -18px;
}

.home__left .white__text_desc {
    margin-left: -18px;
}

.home__container2 .home__inner .home__content .home__right2 {
    background-color: #bcc2be;
    flex: 0.4 1
}

.home__left h1 {
    margin-left: -20px;

}

.vedio__container{

background-color: #eff1f5;
    display: flex;
    height: 580px;
}
.vedio__inner {
    margin: auto;
    width: 80%;
   

}
@media only screen and (max-width: 2300px) {
    .home__content .home__left .black__text_desc {
        color: #42545b;
        font-size: 19px;
        margin-left: -18px;
    }

    .home__left .white__text_desc {
        margin-left: -18px;
    }


}

@media only screen and (max-width: 1201px) {
    .home__right1 img {
        width: 450px;
        height: 450px;


    }


}

@media only screen and (max-width: 800px) {
    .home__right1 img {
        width: 450px;
        height: 450px;

    }



}

@media only screen and (max-width: 768px) {
    .home__right1 img {
        width: 450px;
        height: 450px;
        padding-bottom: 20px;

    }

    .home__content {
        display: flex;

        flex-direction: column;

        justify-content: center;

    }

    .home__right1,
    .home__right2 {
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 480px) {
    .home__right1 img {
        width: 409px;
        height: 420px;

    }

    .home__content {
        display: flex;
        flex-direction: column;
        justify-self: center;
        justify-content: center;


    }


}
.video_containter{
   
    flex-grow: 1; 
   display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 580px;
    width: "100%";
    background-color: #eff1f5;
}

@media only screen and (min-width: 2000px) {
    .video_containter .video_outer_wrapper{
        
        width:1297px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        

    }
    /* .video_wrapper video{
        max-width: 634px;
        min-width: 600px;   
    } */

   
  }
@media only screen and (max-width: 2000px) {
    .video_containter .video_outer_wrapper{
        
        width:1297px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      

    }
    /* .video_wrapper video{
        max-width: 634px;
        min-width: 600px;   
    } */
  }

  @media only screen and (max-width: 1450px) {
    .video_containter .video_outer_wrapper{
        
        width:1297px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    

    }
    /* .video_wrapper video{
        max-width: 634px;
        min-width: 600px;   
    } */
  }
  @media only screen and (max-width: 1315px) {
    .video_containter .video_outer_wrapper{
        
        width:1187px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    

    }
    /* .video_wrapper video{
        max-width: 578px;
        min-width: 578px;   
    } */
  }

  /* @media only screen and (max-width: 1283px) {
    .video_containter .video_outer_wrapper{
        
        width:1240px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
       
    }
   .video_wrapper video{
        width: 605px;
        min-width: 600px;   
    }
  } */
  @media only screen and (max-width: 1200px) {
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
       
    }
   /* .video_wrapper video{
        width: 555px;
        min-width: 400px;   
    } */
  }
  @media only screen and (max-width: 1000px) {
   
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
       
    }
   .video_wrapper video{
        width: 857px;
        min-width: 800px;   
    }
  }
  @media only screen and (max-width: 820px) {
    .video_containter .video_outer_wrapper{
        
        width:"98%" ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
       
    }
   .video_wrapper video{
        width: 759px;
        min-width: 700px;   
    }
  }

  /* @media only screen and (max-width: 800px) {
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
       
    }
   .video_wrapper video{
        width: 370px;
        min-width: 300px;   
    }
  } */
  @media only screen and (max-width: 780px) {
    /* .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
       
    } */
    .video_containter{
     width:100% ;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
   .video_wrapper video{
       width: 709px;
        min-width: 700px;    
    }
  }
  @media only screen and (max-width: 672px) {
  
    .video_containter {
        
        width:100% ;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
       
    }
  .video_containter .video_wrapper > video{
    width: 600px;
    min-width: 590px;  
    }
  }
  @media only screen and (max-width: 600px) {
 .video_containter{
  align-items: center;
  width: 100%;
  height:100%;
 }
   .video_containter .video_wrapper{
    
width: 95%;
   }
   .video_containter .video_wrapper > video{
    width: 100%;
    min-width: 190px;  
    }
    
  }

 
.tip_home__container1 {
    background-color:#263670 ;
    /* height: 600px; */
    display: flex;
    /* padding-top: 2rem; */
    z-index: 9999;
}
.tip_home__inner {
    margin: auto;
    width: 80%;

}
.tip_home__content {
    display: flex;

    align-items: center;
     



}
.tip_home__container1 .tip_home__inner .tip_home__content .tip_home__left {
    flex: 0.6 1;

    flex-wrap: wrap;



}
.tip_home__left>h1 {
    color: #eff1f6;
    font-weight: 500;
}

.tip_home__left>h2 {
    color: #eff1f6;
    font-size: 19px;
    font-weight: 400;
}

.tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
    flex: 0.4 1;
/* justify-content: center; */


}

.tip_home__right1 img {
    width: 167px;
    
    margin-top: 22px;
    

}

@media only screen and (max-width: 2900px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
     margin-left: 349px;
  
    
    
    }

    .tip_home__left .white__text_desc {
        margin-left: -18px;
    }
   
   

}

@media only screen and (max-width: 2300px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-left: 349px;
    
       
    }

    .tip_home__left .white__text_desc {
        margin-left: -18px;
    }
   
   

}
@media only screen and (max-width: 1429px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-left: 303px;
    
       
    }

    .tip_home__left .white__text_desc {
        margin-left: -18px;
    }
   
   

}
@media only screen and (max-width: 1329px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-left: 235px;
    
       
    }

    .tip_home__left .white__text_desc {
        margin-left: -18px;
    }
   
   

}

@media only screen and (max-width: 1201px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-left: 139px;
    
       
    }
    .tip_home__right1 img {
        width: 167px;
        margin-top: 27px;
        
    
    }
   


}
@media only screen and (max-width: 1092px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-right: 143px;
    
       
    }
    .tip_home__right1 img {
        width: 167px;
        margin-top: 27px;
        
    
    }
   


}

@media only screen and (max-width: 800px) {
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-left: 0px;
    
       
    }
    .tip_home__right1 img {
        width: 167px;
        margin-top: 27px;
        
    
    }



}

@media only screen and (max-width: 768px) {
    .tip_home__right1 img {
        width: 167px;
        margin-top: 27px;
        
    
    }
      .tip_home__container1 {
      
        padding-top: 1rem;
      
      }
    .tip_home__content {
        display: flex;

        flex-direction: column;

        justify-content: center;

    }

    .tip_home__right1 {
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 480px) {
    .tip_home__right1 img {
        width: 167px;
        margin-top: 27px;
        
    
    }

    .tip_home__content {
        display: flex;
        flex-direction: column;
        justify-self: center;
        justify-content: center;


    }
    .tip_home__container1 {
      
        padding-top: 1rem;
      
    }
    .tip_home__container1 .tip_home__inner .tip_home__content .tip_home__right1 {
        margin-right: 0px;
    }


}
.Login_layout__1aKZ0 {
    background-color: rgb(239, 240, 240);
    height: calc(97vh - 60px);
    /* height: 93vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
}

.Login_sidebar__28yzi {
    display: none;
}

.Login_login__3W3ZO {
    background-color: white;
    border-radius: 15px;
    width: 80vw;
    height: 40vh;
    max-width: 800px;
    min-height: 310px;
    max-height: 500px;
    padding: 0 4%;

}

.Login_form__3LaYh {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Login_input__2lDTu {
    border-radius: 10px;
    border: 2px solid #313149;
}
.Login_ant-pswd__G1ZvS {
    border-radius: 10px;
    border: 2px solid #313149;
}


.Login_item__oRs44 {
    width: 100%;
}
.LoginAuto_layout__1IgTd {
    background-color: rgb(239, 240, 240);
    height: calc(97vh - 60px);
    /* height: 93vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
}

.LoginAuto_sidebar__16qSk {
    display: none;
}

.LoginAuto_login__B0Bon {
    background-color: white;
    border-radius: 15px;
    width: 80vw;
    height: 40vh;
    max-width: 800px;
    min-height: 310px;
    max-height: 500px;
    padding: 0 4%;

}

.LoginAuto_form__2h8st {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.LoginAuto_input__3znMU {
    border-radius: 10px;
    border: 2px solid #313149;
}
.LoginAuto_ant-pswd__1yxq3 {
    border-radius: 10px;
    border: 2px solid #313149;
}


.LoginAuto_item__1k87G {
    width: 100%;
}
.signUpForm_wrapper__1TS-b {
    max-width: 1024px;
    justify-content: center;
}
.signUpForm_layer__2Sp-r {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.signUpForm_inLayer__3uZ0x {
    background-color: white;
    padding: 10px 0;
}

.signUpForm_smallBox__3d4Cq {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 0 6px;
    width: 100%;
}

.signUpForm_field__OKHUH {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}


.signUpForm_field__OKHUH input {
    background-color: #EDEDED !important;
    color: #181818;
}
.signUpForm_field__OKHUH span span {
    color: #030303;
}
.signUpForm_field__OKHUH::-webkit-input-placeholder {
    color: grey !important;
}
.signUpForm_field__OKHUH::placeholder {
    color: grey !important;
}
.signUpForm_field__OKHUH input::-webkit-input-placeholder {
    color: grey;
}
.signUpForm_field__OKHUH input::placeholder {
    color: grey;
}

.signUpForm_button__2cDuj {
    width: 100%;
    height: 40px;
}
.select-form {
    margin: 10px auto;
    text-align: center;
}

.date-select {
    background-color: rgba(0, 0, 0, 0);
    color: #2f2f2f;
    border-radius: 15px;
    height: 30px;
}

.date {
    width: 100%;
    text-align: center;
    font-size: 25px;
    vertical-align: middle;

    margin-top: 10px;
}

.notification {
    margin: 10px auto;
    font-size: 12px;
}

.isAdministrativeNotify {
    justify-content: center;
    border: 1px solid;
    width: 30px;
    height: 30px;
    font-size: 12px;
    padding: 2px;
    border-radius: 30px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.header,
.header>.row {
    justify-content: center;
}

.header {
    padding: 0 !important;
}

.header.comp {
    display: none;
}

.header.padder {
    padding-bottom: 63px;
}

.nav-item .nav-link {
    padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    color: #f7f5f5;
    opacity: 0.8;
    text-decoration: none;
    font-size: 1.1rem;
    transition: all 0.2s ease;
}


.header-head {
    top: 0;
    left: 0;
    background: #900;
    width: 100%;
    height: 60px;
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
    box-shadow: 0 0 7px #2f2f2f;
}

.header-head.about {
    background: #3C3D3F;
}

.header-head.fixed {
    position: fixed;
    background: #900;
    transition: background 0.5s;
}

.header-icons {
    margin: 0 10px 0 5px;
}

.header-title {
    /* text-align: right; */
    color: #ffffff;
}

.header-title-hr {
    border-color: #e7e7e7;
    border-style: solid;
    border-width: 0.7px;
    width: 30%;
    margin: 5px auto;
}

#screen-title {
    font-weight: bolder;
    font-size: 1.5rem;
    text-shadow: 2px 2px 2px #423d3d80;
}

.header-dropdown-button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    /* transform: translate(-100%, 60%); */
    /* font-size: 1.8rem; */
    cursor: pointer;
}


.app_link {
    margin-top: 10px;
}


/*********************************/
/*    NAVBAR                     */
/*********************************/

.navbar-custom {
    background-color: #900;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    font: 700 0.875rem/0.875rem "Open Sans", sans-serif;
    transition: all 0.2s;
}

.navbar-custom .container {
    max-width: 87.5rem;
}

.navbar-custom .navbar-brand.logo-image img {
    width: 12rem;
    height: 3rem;
}

/*.navbar-custom .navbar-brand.logo-image.active img {*/
/*	width: 12rem;*/
/*	height: 3rem;*/
/*}*/

.navbar-custom .navbar-brand.logo-text {
    font: 700 2rem/1.5rem "Open Sans", sans-serif;
    color: #fff;
    ;
    text-decoration: none;
}

.navbar-custom .navbar-nav {
    margin-top: 0.75rem;
    /* margin-bottom: 0.5rem; */
}

.navbar-custom .nav-item .nav-link {
    padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    color: #f7f5f5;
    opacity: 0.8;
    text-decoration: none;
    font-size: 1.1rem;
    transition: all 0.2s ease;
}



.navbar-custom .nav-item .nav-link.active {
    color: #fff;
    opacity: 1;

    display: flex;
    align-items: center;
}

.nav-menu {
    list-style: none;
    padding-left: 0px;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: -1;
}

.nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
}

/* Dropdown Menu */
.navbar-custom .dropdown.active>.dropdown-menu {
    display: block;
    /* this makes the dropdown menu stay open while hovering it */
    min-width: auto;
    -webkit-animation: fadeDropdown 0.2s;
            animation: fadeDropdown 0.2s;
    /* required for the fade animation */
}

@-webkit-keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.navbar-custom .dropdown-toggle:focus {
    /* removes dropdown outline on focus */
    outline: 0;
}

.navbar-custom .dropdown-menu {
    margin-top: 0;
    border: none;
    border-radius: 0.25rem;
    background-color: #900;
}

.navbar-custom .dropdown-item {
    color: #f7f5f5;
    opacity: 0.8;
    font: 700 0.875rem/0.875rem "Open Sans", sans-serif;
    text-decoration: none;
}

.container .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-custom .dropdown-item:hover {
    background-color: #900;
    color: #fff;
    opacity: 1;
}

.navbar-custom .dropdown-items-divide-hr {
    width: 100%;
    height: 1px;
    margin: 0.75rem auto 0.725rem auto;
    border: none;
    background-color: #c4d8dc;
    opacity: 0.2;
}

/* end of dropdown menu */

.navbar-custom .nav-item .btn-outline-sm {
    margin-top: 0.15rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    border: 0.125rem solid #fff;
    color: #fff;

}

.navbar-custom .nav-item .btn-outline-sm:hover {
    background-color: #fff;
    color: #900;
}

.navbar-custom .navbar-toggler {
    padding: 0;
    border: none;
    color: #fff;
    font-size: 2rem;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times {
    display: none;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars {
    display: inline-block;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars {
    display: none;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times {
    display: inline-block;
    margin-right: 0.125rem;
}
.Top5_layer__7eCUn {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}
body {
    font-family: 'Noto Sans KR', sans-serif !important;
}

.StockCard_container__2m0SY {
    border-radius: 30px;
    margin-top: 15px;
    color: white;
    height: 270px;
    padding: 10px;
    background-color: #233886;
    position: relative;
}
.StockCard_rise__1u0wo{
    background-color: #990000;
}

.StockCard_neutral__3XKqu {
    background-color: gray;
    box-shadow: 0 0 10px gray;
}

.StockCard_type__10Ge4 {
    padding: 0.7rem;
    font-size: 1vw;
    white-space: nowrap;
}

.StockCard_name__2sezK {
    font-size: 15px;
    width: auto;
}

.StockCard_detailed_info__1wpLv {
    border: white solid 1px;
    border-radius: 15px;
    font-size: 15px;
    width: auto;
    cursor: pointer;
    padding: 2px 5px;
    transition: all 0.5s;
}

.StockCard_detailed_info__1wpLv:hover {
    background-color: white;
    color: black;
    transition: all 0.5s;
}

.StockCard_id__2rFVq{
 font-size: 16px;
}

.StockCard_title__1HlLc {
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 5px;
    /* padding-left: 15px; */
    font-size: 15px;
}

.StockCard_percentage__1riyZ {
    justify-content: center;
    font-family: 'Signika', sans-serif;
    font-size: 30px;
    font-weight: bolder;
    padding-bottom: 1rem;
}

.StockCard_candle_chart_button__1ofNd {
    position: absolute;
    bottom: 20px;
    right: 40px;
    padding: 5px;
    border: 1px solid white;
    border-radius: 10px;
    cursor: pointer;
    transition: all 1s;
}

.StockCard_isAdministrative__1Vh1W {
    justify-content: center;
    border: 1px solid;
    width: 30px;
    height: 30px;
    font-size: 12px;
    padding: 2px;
    border-radius: 30px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.StockCard_detail_info_content__1PHfo {
    display: flex;
    justify-content: space-between;
    padding: 3px;
    border-bottom: 2px solid #c5c5c5;
}

.StockCard_detail_info_content_header__1s243 {
    color: #9e9e9e;
}

.StockCard_detail_info_content_info__3qPPK {
    font-weight: bold;
}

.StockCard_priceStyle__3tOlA {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}

@media only screen and (min-width: 900px)
{
    .StockCard_candle_chart_button__1ofNd:hover {
        background-color: white;
        color: black;
        transition: all 1s;
    }  
}

@media only screen and (max-width: 900px)
{
    .StockCard_name__2sezK {
        font-size: 12px;
        width: auto;
    }
    
    .StockCard_detailed_info__1wpLv {
        font-size: 12px;
    }

    .StockCard_id__2rFVq {
        font-size: 12px;
    }

    .StockCard_type__10Ge4 {
        padding: 5px 0px;
    }
    
    .StockCard_container__2m0SY {
        height: 250px;
    }
}

@media(max-width: 450px) {
     .StockCard_name__2sezK {
        font-size: 14px;
    }
    .StockCard_id__2rFVq {
        font-size: 16px;
    }
     .StockCard_type__10Ge4 {
        padding-bottom: 0;
        padding-top: 0;
    }
    .StockCard_container__2m0SY {
        height: 250px;
    }
    .StockCard_priceStyle__3tOlA {
        font-size: 12px;
    }
}


.additional-div {
    background-color: #2f2f2f;
    text-align: center;
    border-radius: 30px;
    margin-top: 15px;
    color: white;
    height: 270px;
    padding: 10px;
}

.total-change-model {
    font-size: 18px;
}

.total-change-content {
    font-family: 'Signika', sans-serif;
    font-size: 20px;
}

.total-change-row {
    margin-top: 10px;
}

.total-change-container {
    width: 100%;
    padding-top: 10px;
}

.total-change-hr {
    border-color: #e7e7e7;
    width: 20%;
    margin: 8px auto;
}

.total-change-rest {
    font-size: 14px;
}


@media only screen and (max-width: 900px)
{
    .additional-div {
        height: 250px;
    }
}

@media(max-width: 450px) {
    .additional-div {
        height: 250px;
    }
}

.SignalSector_layer__1nA5y {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 12px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 10px 0;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    cursor: pointer;
}
.SignalSector_layer__1nA5y:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: scale 1s;
}
.SignalSector_layer__1nA5y:hover .SignalSector_image__1borO {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: scale 4s ease-in-out 1s;
}
.SignalSector_layer__1nA5y:hover .SignalSector_text__37Lwm {
    font-weight: bold;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: scale 1s;
}

.SignalSector_image__1borO {
    width: 35px;
    height: 35px;
    transition:  scale 1s;
}
.SignalSector_text__37Lwm {
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 14px;
}

.SignalSector_button__1gFbL {
    background-color: #EDEDED;
    width: 100%;
    height: 60px;
    padding: 6px;
    margin: 6px 0;
}

.SignalSector_selectedButton__31BbY {
    background-color: #990000;
    width: 100%;
    height: 60px;
    padding: 6px;
    margin: 6px 0;
}

.SignalSector_disabledButton__27PSI {
    background-color: #4a4a4a;
    width: 100%;
    height: 60px;
    padding: 6px;
    margin: 6px 0;
}
.chooseDate_wrapper__2yMmJ {
    max-width: 1024px;
    justify-content: center;
}

.chooseDate_smallBox__1-fGw {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 3px 6px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.chooseDate_active__NLvk- {
    color: #990000;
    font-weight: bold;
}

.chooseDate_button__3Y-Gs {
    background-color: #EDEDED;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 0;
}

.chooseDate_selectedButton__116gq {
    background-color: #990000;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 0;
}

.chooseDate_disabledButton__eMYD5 {
    background-color: #4a4a4a;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 0;
}
.entry {
    color: #233886;
    background-color: #3C3D3F;
}

#overview-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 2.0em;

    background-clip: padding-box;

    border: 0 solid white;
    border-radius: 15px;
}

.overview-chart {
    width: 90%;
    margin: 30px auto 20px auto;
    justify-content: center;
    background: #FFFFFF;
    border: 0 solid white;
    border-radius: 15px;

    font-size: 20px;
    box-shadow: 0 0 10px #BBB;
}



.overview-content {
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 14px;

    width: 90%;
    margin-right: auto;
    margin-left: auto;
    color: #2f2f2f;

    font-size: 1.2rem;
    /*justify-content: center;*/
}

.overview-content strong {
    font-family: 'Signika', sans-serif;
}

.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.inLayer {
    background-color: white;
    padding: 10px 0;
}
.Overview_entry__3zTnj {
    color: #233886;
    background-color: #3C3D3F;
}

#Overview_overview-container__H-SeA {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 2.0em;

    background-clip: padding-box;

    border: 0 solid white;
    border-radius: 15px;
}

.Overview_overview-chart__3lICe {
    width: 90%;
    margin: 30px auto 20px auto;
    justify-content: center;
    background: #FFFFFF;
    border: 0 solid white;
    border-radius: 15px;

    font-size: 20px;
    box-shadow: 0 0 10px #BBB;
}



.Overview_overview-content__3MSj5 {
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 14px;

    width: 90%;
    margin-right: auto;
    margin-left: auto;
    color: #2f2f2f;

    font-size: 1.2rem;
    /*justify-content: center;*/
}

.Overview_overview-content__3MSj5 strong {
    font-family: 'Signika', sans-serif;
}

.Overview_layer__3lkvb {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.Overview_inLayer__3oVze {
    background-color: white;
    padding: 10px 0;
}
.About_about__2pjjl {
    /* padding-bottom: 10px; */
}
.About_contentSection__285mH{
    position: absolute;
    top: 50px;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.About_container__JKlQZ {
    background-color: #F6EFE8;
    color: black;
    font-family: 'Nanum Gothic', sans-serif;
    text-align: center;
    font-size: 14px;
}

.About_last__1qAJM {
    margin-bottom: 30px;
}

.About_left_hr__2yZEB {
    border-top-style: solid;
    border-top-color: white;
    border-top-width: 1px;
    width: 20%;
    margin: 15px 0;
}

.About_right_hr__1poQr {
    border-top-style: solid;
    border-top-color: white;
    border-top-width: 1px;
    width: 20%;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: auto;
}

.About_center_hr__3j2fn {
    border-top-style: solid;
    border-top-color: white;
    border-top-width: 1px;
    width: 20%;
    margin: 15px auto;
}

.About_about_1__17MdV {
    width: 100%;
    background-color: white;
    color: #000000;
    padding-left: 7%;
    padding-right: 7%;
}

.About_about_2__Jx0QE {
    width: 100%;
    background-color: #3C3D3F;
    color: white;
    padding-left: 7%;
    padding-right: 7%;
}

.About_about_3__2ZOR0 {
    width: 100%;
    background-color: #F6EFE8;
    color: black;
    padding-left: 7%;
    padding-right: 7%;
}

.About_about_4__2pEE4 {
    width: 100%;
    background:  #990000;
    color: white;
    padding-left: 7%;
    padding-right: 7%;
    /* padding-bottom: 40px; */
}

.About_about_5__9k3MK {
    width: 100%;
    background: linear-gradient(20deg, rgba(96, 189, 140, 1) 0%, rgba(75, 172, 206, 1) 70%, rgba(75, 172, 206, 1) 100%);
    color: white;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 40px;
}

.About_about_overview__3eDts {
    width: 100%;
    background-color: #F6EFE8;
    color: black;
}

.About_about_left_topic__3jVbW {
    padding-top: 30px;
    font-size: 25px;
    text-align: left;
}

.About_about_right_topic__1KCms {
    padding-top: 30px;
    font-size: 25px;
    text-align: right;
}

.About_about_center_topic__2KGGW {
    padding-top: 30px;
    font-size: 25px;
    text-align: center;
}

.About_about_content__z9c68 {
    text-align: left;
    word-break: keep-all;
    line-height: 25px;
    padding-bottom: 30px;
}

.About_about_center_content__2q4vS {
    text-align: center;
    word-break: keep-all;
    line-height: 25px;
    padding-bottom: 30px;
}

.About_about_home__3AqC4 {
    width: 60%;
    font-size: 18px;
    color: #FFFFFF;

    background-color: #990000;
    border-style: solid;

    border-width: 1px;
    border-color: #990000;
    border-radius: 10px;

    margin-left: auto;
    margin-right: auto;

    padding-top: 5px;
    padding-bottom: 5px;
}

.About_footer__1pS_r {
    width: 100%;
    background: #2b2b2b;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #b1b1b1;
}

.About_footer__1pS_r a:link {
    color: #b1b1b1;
}

.About_footer__1pS_r a:visited {
    color: #b1b1b1;
}

.About_footer__1pS_r a:focus {
    color: #b1b1b1;
}

.About_footer__1pS_r a:hover {
    color: #b1b1b1;
}

.About_footer_container__2OfvK {
    font-size: 13px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.About_footer_company__2wjPU {
    text-align: left;
    font-size: 11px;
}

.About_footer_company_name__3GQS9 {
    font-weight: bold;
}

.About_footer_company_detail__3l7U- {
    margin-top: 5px;
}

.About_footer_hr__3ycFl {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 30%;
    border-top-style: solid;
    border-top-color: white;
}

.About_incDetail__DN4yA {
    color:  #b1b1b1;
    text-align: left;
}

.About_incName__FUT0l {
    font-size: 24px;
    font-weight: bold;
    color:  #b1b1b1;
}
.About_incHr__2l2J7 {
    border-top: 1px dotted #f5f7f7;
}
.profile-background-img {
    width: 100%;
    height: 180px;
}

.profile-top-view {
    text-align: center;
}

.profile-person {
    width: 170px;
    height: 170px;
    margin: 50px auto 10px auto;
}

.profile-person-img {
    width: 170px;
    height: 170px;
    /* background-color: #b1b1b1; */
    background-color: transparent;
    border-radius: 50%;
}

.profile-name {
    font-size: 16px;

    color: #838080;
}

.profile-username {
    font-size: 22px;
}

.invite-container {
    margin-top: 20px;
    margin-bottom: 0;
    display: inline-block;
}

.invite-right {
    margin-left: 50px;
}

.invite-row {
    margin-top: 22px;
}

.invite-item {
    font-size: 18px;
}

.invite-name {
    font-size: 12px;
    color: #838080;
}

.profile-container {
    padding-bottom: 1.8em;
}

.profile-specific-view {
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
}

.profile-hr {
    border-top-color: #838080;
    border-top-style: solid;
    margin: auto auto 20px;

    /*width: 60%;*/
    align-self: center;
    text-align: center;
}

.profile-title {
    font-size: 13px;
    color: #ababab;
    margin: 10px 0;
}

.profile-content {
    font-size: 16px;
}

.profile-logout {
    border: #2f2f2f solid 0.5px;
    /* color: #2f2f2f; */
    background-color: #2f2f2f;
    border-radius: 15px;
    text-align: center;
    width: 60%;
    margin: 30px auto;

    cursor: pointer;
}

.logout-button:link {
    color: white;
}

.logout-button:visited {
    color: white;
}

.logout-button:hover {
    color: white;
}
.updateInfo_wrapper__FlLSw {
    max-width: 1024px;
    justify-content: center;
}
.updateInfo_layer__2vhZd {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.updateInfo_inLayer__3WrHQ {
    background-color: white;
    padding: 10px 0;
}

.updateInfo_smallBox__2brUk {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 0 6px;
    width: 100%;
}

.updateInfo_field__29pGy {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}


.updateInfo_field__29pGy input {
    background-color: #EDEDED !important;
    color: #181818;
}
.updateInfo_field__29pGy span span {
    color: #030303;
}
.updateInfo_field__29pGy::-webkit-input-placeholder {
    color: grey !important;
}
.updateInfo_field__29pGy::placeholder {
    color: grey !important;
}
.updateInfo_field__29pGy input::-webkit-input-placeholder {
    color: grey;
}
.updateInfo_field__29pGy input::placeholder {
    color: grey;
}

.updateInfo_button__IaifJ {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    padding: 6px;
    color: white;
    font-weight: 600;
}
.updateInfo_copy__174DK div {
    margin-top: 10px;
    width: 100%;
}

.updateInfo_passwordModal__2ZEom {
    padding: 0 !important;
}
.changePassword_wrapper__1dzqc {
    max-width: 1024px;
    justify-content: center;
}
.changePassword_layer__GdnOo {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.changePassword_inLayer__1yd5G {
    background-color: white;
    padding: 10px 0;
}

.changePassword_smallBox__1ZFip {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 0 6px;
    width: 100%;
}

.changePassword_field__1ce2k {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}


.changePassword_field__1ce2k input {
    background-color: #EDEDED !important;
    color: #181818;
}
.changePassword_field__1ce2k span span {
    color: #030303;
}
.changePassword_field__1ce2k::-webkit-input-placeholder {
    color: grey !important;
}
.changePassword_field__1ce2k::placeholder {
    color: grey !important;
}
.changePassword_field__1ce2k input::-webkit-input-placeholder {
    color: grey;
}
.changePassword_field__1ce2k input::placeholder {
    color: grey;
}

.changePassword_button__1K_zh {
    margin-top: 10px;
    width: 100%;
    height: 40px;
}
.PaymentInfo_leftdiv__2o9le {
    float: left;
    width: 500px;

}

.PaymentInfo_rightdiv__1DhcG {
    float: left;
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
}

/*div {*/
/*    color: black;*/
/*    background-color: rgb(223, 217, 217);*/
/*    height: 50px;*/
/*    font-size: 12px;*/
/*}*/

.PaymentInfo_btn0__1AvEF {
    margin-right: 50px;
    width: 300px;
    height: 40px;
}

.PaymentInfo_btn1__29NQD {
    margin-left: 50px;
    width: 300px;
    height: 40px;
}

.PaymentInfo_card_layer__3MHEM {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.PaymentInfo_layer__2gsOP {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    /* margin: 15px; */
    border-radius: 3px;
}

.PaymentInfo_inLayer__S246n {
    background-color: white;
    padding: 10px 0;
}

.PaymentInfo_p_header__3IaRI {
    padding: 10px 0 0 0;
    margin: 0px 5px
}

.PaymentInfo_section__18une {
    margin: 30px;
}

.PaymentInfo_content__2wHVd {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.PaymentInfo_main_divider__31J5P {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}

.PaymentInfo_content_divider__-YLcX {
    margin: 5px 0;
}

.PaymentInfo_free_table__116wh {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 0px 0px 15px 0px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.PaymentInfo_button__ZKCNe {
    width: 100%;
    height: 40px;
    /* margin: 5px; */
    padding: 6px;
    color: white;
    font-weight: 600;
}

.PaymentInfo_disabledButton__2Ii7j {
    background-color: #4a4a4a;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 0;
}
.ElseInfo_wrapper__16BBq {
    max-width: 1024px;
    justify-content: center;
}
.ElseInfo_layer__3z1lO {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.ElseInfo_inLayer__8a8tF {
    background-color: white;
    padding: 10px 0;
}

.ElseInfo_smallBox__3QJBl {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 0 6px;
    width: 100%;
}

.ElseInfo_field__1t11o {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}


.ElseInfo_field__1t11o input {
    background-color: #EDEDED !important;
    color: #181818;
}
.ElseInfo_field__1t11o span span {
    color: #030303;
}
.ElseInfo_field__1t11o::-webkit-input-placeholder {
    color: grey !important;
}
.ElseInfo_field__1t11o::placeholder {
    color: grey !important;
}
.ElseInfo_field__1t11o input::-webkit-input-placeholder {
    color: grey;
}
.ElseInfo_field__1t11o input::placeholder {
    color: grey;
}

.ElseInfo_button__3fSlS {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    color: white;
    font-weight: 600;
}
.ElseInfo_copy__3InPg div {
    width: 100%;
}

.ElseInfo_main_divider__3CD5P {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}
.Unregister_wrapper__37KVX {
    max-width: 1024px;
    justify-content: center;
}
.Unregister_layer__1BH4X {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.Unregister_inLayer__PZElK {
    background-color: white;
    padding: 10px 0;
}

.Unregister_smallBox__cSPZD {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 0 6px;
    width: 100%;
}

.Unregister_field__4iX6q {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}


.Unregister_field__4iX6q input {
    background-color: #EDEDED !important;
    color: #181818;
}
.Unregister_field__4iX6q span span {
    color: #030303;
}
.Unregister_field__4iX6q::-webkit-input-placeholder {
    color: grey !important;
}
.Unregister_field__4iX6q::placeholder {
    color: grey !important;
}
.Unregister_field__4iX6q input::-webkit-input-placeholder {
    color: grey;
}
.Unregister_field__4iX6q input::placeholder {
    color: grey;
}

.Unregister_button__3LXHw {
    margin-top: 10px;
    width: 100%;
    height: 40px;
}
.Unregister_copy__2rtyp div {
    width: 100%;
}

.Unregister_main_divider__2b9Rw {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}
.Navbar_icons__1d51A {
    width: 28px;
}

.Navbar_menu_item__3Gd-A a {
    color: white;
}
.Navbar_menu__dNr4z {
    z-index: 9999999;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0;
    /* Works well with 100% width  */
    width: 100vw;
    font-size: 0.9em;
    padding: 0.5em 0.5em;
    align-items: center;
    justify-content: center;
    background-color: #990000;
    box-shadow: 0 0 10px #2f2f2f;
}


.Navbar_menu_item__3Gd-A {
    all: unset;
    flex-grow: 1;
    z-index: 100;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    align-items: center;
    will-change: transform;
    justify-content: center;
    padding: 0.55em 0 0.85em;
    transition: -webkit-transform 3ms;
    transition: transform 3ms;
    transition: transform 3ms, -webkit-transform 3ms;
    border: none;
    outline: none;
}

.Navbar_menu_item__3Gd-A::before {
    content: "";
    z-index: -1;
    width: 4.7em;
    height: 4.7em;
    border-radius: 50%;
    position: absolute;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: background-color 0.7s, -webkit-transform 2ms;
    transition: background-color 0.7s, transform 2ms;
    transition: background-color 0.7s, transform 2ms, -webkit-transform 2ms;
}


.Navbar_menu_item__3Gd-A.Navbar_active__2sezb {
    -webkit-transform: translate3d(0, -.8em, 0);
            transform: translate3d(0, -.8em, 0);
}


.Navbar_menu_item__3Gd-A.Navbar_active__2sezb::before {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #F7A73B
}


.Navbar_icon__1TZ-Y {
    width: 2.6em;
    height: 2.6em;
    stroke: white;
    fill: transparent;
    stroke-width: 1pt;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 400;

}

.Navbar_menu_item__3Gd-A.Navbar_active__2sezb .Navbar_icon__1TZ-Y {
    animation: Navbar_strok__3Pg8s 1.5s reverse;
}

@-webkit-keyframes Navbar_strok__3Pg8s {
    100% {
        stroke-dashoffset: 400;
    }
}

@keyframes Navbar_strok__3Pg8s {
    100% {
        stroke-dashoffset: 400;
    }
}

.Navbar_menu_border__3IPJZ {
    bottom: 99%;
    width: 10.9em;
    height: 1.8em;
    position: absolute;
    -webkit-clip-path: url(#menu);
            clip-path: url(#menu);
    will-change: transform;
    background-color: #990000;
    border: 0 solid transparent;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
}


.Navbar_svg_container__2EayU {
    width: 0;
    height: 0;
    -webkit-transform: translateX(20);
            transform: translateX(20);
}

.Navbar_st0__SbuhI{fill:#FFFFFF;}
.ForgetUserInfo_layer__2YAS9 {
    background-color: rgb(239, 240, 240);
    height: 100vh;

    justify-content: center;
    align-items: center;

}
.ForgetUserInfo_button__2aeqj {
    margin-top: 50px;
    width: 100%;
    height: 40px;
}
.findPassword_wrapper__1H8j5 {
    max-width: 1024px;
    justify-content: center;
}
.findPassword_layer__34-4U {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.findPassword_inLayer__1Zzy9 {
    background-color: white;
    padding: 10px 0;
}

.findPassword_smallBox__30_w5 {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 0 6px;
    width: 100%;
}

.findPassword_field__1ME9Q {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}


.findPassword_field__1ME9Q input {
    background-color: #EDEDED !important;
    color: #181818;
}
.findPassword_field__1ME9Q span span {
    color: #030303;
}
.findPassword_field__1ME9Q::-webkit-input-placeholder {
    color: grey !important;
}
.findPassword_field__1ME9Q::placeholder {
    color: grey !important;
}
.findPassword_field__1ME9Q input::-webkit-input-placeholder {
    color: grey;
}
.findPassword_field__1ME9Q input::placeholder {
    color: grey;
}

.findPassword_button__38eEi {
    width: 100%;
    height: 40px;
}
.findPassword_radio__3E1G7 .findPassword_ant-radio-checked__3drzd span{
    border-color: #990000;
}
.findPassword_radio__3E1G7 .findPassword_ant-radio-checked__3drzd span:after {
    background-color: #990000;
}
.findUserId_wrapper__2EcT5 {
    max-width: 1024px;
    justify-content: center;
}
.findUserId_layer__16BHR {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.findUserId_inLayer__oXW_L {
    background-color: white;
    padding: 10px 0;
}

.findUserId_smallBox__1sMP- {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 0 6px;
    width: 100%;
}

.findUserId_field__2nmnE {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}


.findUserId_field__2nmnE input {
    background-color: #EDEDED !important;
    color: #181818;
}
.findUserId_field__2nmnE span span {
    color: #030303;
}
.findUserId_field__2nmnE::-webkit-input-placeholder {
    color: grey !important;
}
.findUserId_field__2nmnE::placeholder {
    color: grey !important;
}
.findUserId_field__2nmnE input::-webkit-input-placeholder {
    color: grey;
}
.findUserId_field__2nmnE input::placeholder {
    color: grey;
}

.findUserId_button__2d0Fa {
    width: 100%;
    height: 40px;
}
.findUserId_radio__3In0C .findUserId_ant-radio-checked__2UOhC span{
    border-color: #990000;
}
.findUserId_radio__3In0C .findUserId_ant-radio-checked__2UOhC span:after {
    background-color: #990000;
}
.ErrorPage_layout__1sGZ- {
    background-color: rgb(239, 240, 240);
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.ErrorPage_image__39GuY {
    width: 80vw;
}
.ErrorPage_link__2Uo_r:link, .ErrorPage_link__2Uo_r:visited, .ErrorPage_link__2Uo_r:hover, .ErrorPage_link__2Uo_r:active{
    color: rgb(38, 48, 60);
    font-size: 16px;
    text-decoration: none;
    width: 140px;
}
.ErrorPage_button__3tarS {
    border-radius: 50px;
    background-color: #990000;
    justify-content: center;
    color: white;
}

@media (min-width: 600px) {
    .ErrorPage_image__39GuY {
        width: 600px;
    }
}
.Payment_leftdiv__3R885 {
    float: left;
    width: 500px;

}

.Payment_rightdiv__1tvyP {
    float: left;
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
}

/*div {*/
/*    color: black;*/
/*    background-color: rgb(223, 217, 217);*/
/*    height: 50px;*/
/*    font-size: 12px;*/
/*}*/

.Payment_btn0__lKAG5 {
    margin-right: 50px;
    width: 300px;
    height: 40px;
}

.Payment_btn1__3aV3I {
    margin-left: 50px;
    width: 300px;
    height: 40px;
}

.Payment_layer__3XjpP {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 20px;
    border-radius: 3px;
}

.Payment_p_header__CrnXv {
    padding: 10px 0 0 0;
    margin: 0px 5px
}

.Payment_section__4Mdw9 {
    margin: 30px;
}

.Payment_content__1vAFh {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.Payment_main_divider__3e1RO {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}

.Payment_content_divider__2eZXO {
    margin: 5px 0;
}

.Payment_free_table__3WwgH {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 15px 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.Payment_button__2yuO1 {
    width: 100%;
    height: 40px;
    /* margin: 5px; */
}

.Payment_header__2RjG9 {
    background-color: #990000;
    padding: 5px 15px;
    color: white;
    border-radius: 20px;
    font-weight: 600;
}

.Payment_payment_body__Qt-ag {
    height: 1223px;
    background: linear-gradient(
        to bottom,
        #e6ebef 0%,
        #e6ebef 50%,
        #990000 50%,
        #990000 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
}

.Payment_payment_container__1G-Qb {
    height: 1000px;
    width: 1400px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Payment_payment_container__1G-Qb .Payment_payment_inner_container__3jQmF {
    padding: 0px 200px;
}

.Payment_payment_title__2IsyC {
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    color: #990000;
    margin-top: 30px;
    font-family: 'Noto Sans KR', sans-serif;
}

.Payment_payment_box__gOeNT {
    height: 500px;
    border: 1px solid #d8dfeb;
    border-radius: 10px;
}

.Payment_payment_box__gOeNT div {
    text-align: center;
}

.Payment_payment_sub_type__2qK7K {
    font-size: 30px;
    color: #d9dde8;
    font-weight: 700;
}

.Payment_payment_price__2rM-q {
    font-size: 40px;
    font-weight: 900;
    color: #90a3bf;
}

.Payment_payment_info__3HAyu {
    display: flex; 
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 10px;
}

.Payment_payment_info_title__KmbNx {
    color: #90a3bf;
    font-weight: 700;
}

.Payment_payment_info_content__3FDHa {
    color: #90a3bf;
    font-weight: 500;
}

.Payment_select_button__3-kPD {
    width: 160px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #d8dfeb;
    font-weight: 600;
    color: #90a3bf;
    border-radius: 10px;
    font-size: 15px;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 80px);
    cursor: pointer;
}

.Payment_rate_container__3D5RR {
    margin: 30px 0px
}

.Payment_rate__21peM {
    font-size: 40px;
    color: #ff9933
}

.Payment_payment_memType_container__1sfUd {
    display: flex;
}

.Payment_payment_price_container__2P4xA {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 1500px)
{
    .Payment_payment_body__Qt-ag {
        height: 1033px;
    }

    .Payment_payment_container__1G-Qb {
        height: 1000px;
        width: 1000px;
    }
    
    .Payment_payment_container__1G-Qb .Payment_payment_inner_container__3jQmF {
        padding: 0px 100px;
    }

    .Payment_header__2RjG9 {
        padding: 5px 8px;
    }
}

@media only screen and (max-width: 992px)
{
    .Payment_payment_body__Qt-ag {
        height: auto;
        background: white;
    }

    .Payment_payment_container__1G-Qb {
        height: auto;
        width: 1000px;
        border-radius: 0px;
    }

    .Payment_payment_container__1G-Qb .Payment_payment_inner_container__3jQmF {
        padding: 0px 20px;
    }

    .Payment_payment_box__gOeNT {
        margin-bottom: 20px;
        height: auto;
    }

    .Payment_select_button__3-kPD {
        position: relative;
        bottom: auto;
        left: auto;
        margin-bottom: 20px;
    }

    .Payment_payment_sub_type__2qK7K {
        font-size: 20px;
    }
    
    .Payment_payment_price__2rM-q {
        font-size: 30px;
        font-weight: 900;
        color: #90a3bf;
    }

    .Payment_rate_container__3D5RR {
        margin: 10px 0px
    }

    .Payment_rate__21peM {
        font-size: 30px;
        color: #ff9933;
    }

    .Payment_payment_radio_header__1kTCM {
        margin-right: auto;
    }
    
}

@media only screen and (max-width: 430px) {
    .Payment_payment_memType_container__1sfUd {
        flex-direction: column;
    }

    .Payment_header__2RjG9 {
        margin-right: auto
    }

    .Payment_payment_price_container__2P4xA {
        flex-direction: column;
        align-items: start;
    }
}
.FAQPage_wrapper__38n2B {
    max-width: 1024px;
    justify-content: center;
}

.FAQPage_layer__2oM2l {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.FAQPage_title__16M_M {
    margin: 30px 0;
}

.FAQPage_section__3KG9g {
    margin: 30px;
}

.FAQPage_image__1ylbV img {
    max-width: 70%;
    height: auto;
    display: block;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    margin: 0px auto;
}

.FAQPage_button__2bWwT {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    width: 30%;
    height: 40px;
}
.AskQuestion_wrapper__34Tsz {
    max-width: 1024px;
    justify-content: center;
}
.AskQuestion_layer__2SmgN {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.AskQuestion_inLayer__3Rfst {
    background-color: white;
    padding: 10px 0;
}

.AskQuestion_smallBox__LSnth {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 0 6px;
    width: 100%;
}

.AskQuestion_field__1mGsT {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}


.AskQuestion_field__1mGsT input {
    background-color: #EDEDED !important;
    color: #181818;
}
.AskQuestion_field__1mGsT span span {
    color: #030303;
}
.AskQuestion_field__1mGsT::-webkit-input-placeholder {
    color: grey !important;
}
.AskQuestion_field__1mGsT::placeholder {
    color: grey !important;
}
.AskQuestion_field__1mGsT input::-webkit-input-placeholder {
    color: grey;
}
.AskQuestion_field__1mGsT input::placeholder {
    color: grey;
}

.AskQuestion_button__3OFGF {
    width: 100%;
    height: 40px;
}
.Survey_wrapper__3hJoo {
    max-width: 1024px;
    justify-content: center;
}
.Survey_layer__3fYwN {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.Survey_inLayer__2iCDS {
    background-color: white;
    padding: 10px 20px;
    margin: 15px;
    border-radius: 3px;
}

.Survey_question__kaJfR {
    margin: 15px;
}

.Survey_textReply__1Ry4_ {
    margin: 20px 10px;
}

.Survey_field__13tCq {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}

.Survey_field__13tCq input {
    background-color: #EDEDED !important;
    color: #181818;
}
.Survey_field__13tCq span span {
    color: #030303;
}
.Survey_field__13tCq::-webkit-input-placeholder {
    color: grey !important;
}
.Survey_field__13tCq::placeholder {
    color: grey !important;
}
.Survey_field__13tCq input::-webkit-input-placeholder {
    color: grey;
}
.Survey_field__13tCq input::placeholder {
    color: grey;
}

.Survey_answerBox__3TQ-O {
    background-color: white;
    border: thin solid #EDEDED;
    height: 50px;
    color: black;
    /* margin: 10px; */
    width: 100%;
    border-radius: 5px;
}

.Survey_button__1tw98 {
    width: 30%;
    height: 40px;
}

.CreditcardForm_leftdiv__L-TQf {
    float: left;
    width: 500px;

}

.CreditcardForm_rightdiv__1tLCE {
    float: left;
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
}

/*div {*/
/*    color: black;*/
/*    background-color: rgb(223, 217, 217);*/
/*    height: 50px;*/
/*    font-size: 12px;*/
/*}*/

.CreditcardForm_btn0__Jyv1A {
    margin-right: 50px;
    width: 300px;
    height: 40px;
}

.CreditcardForm_btn1__3e71v {
    margin-left: 50px;
    width: 300px;
    height: 40px;
}

.CreditcardForm_layer__2YNu4 {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 20px;
    border-radius: 3px;
}

.CreditcardForm_inLayer__1ZcQG {
    background-color: white;
    padding: 10px;
}

.CreditcardForm_p_header__3DgEj {
    padding: 10px 0;
}

.CreditcardForm_section__8x87X {
    margin: 30px;
}

.CreditcardForm_content__2zovu {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.CreditcardForm_main_divider__1k7wk {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}

.CreditcardForm_content_divider__3NEKJ {
    margin: 5px 0;
}

.CreditcardForm_free_table__woUBH {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.CreditcardForm_button__1jKS2 {
    width: 100%;
    height: 40px;
    /* margin: 5px; */
}

body {
    font-family: 'Noto Sans KR', sans-serif;
}

.VAForm_leftdiv__144ZW {
    float: left;
    width: 500px;

}

.VAForm_rightdiv__2RtQR {
    float: left;
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
}

/*div {*/
/*    color: black;*/
/*    background-color: rgb(223, 217, 217);*/
/*    height: 50px;*/
/*    font-size: 12px;*/
/*}*/

.VAForm_btn0__2Z-p- {
    margin-right: 50px;
    width: 300px;
    height: 40px;
}

.VAForm_btn1__O9P3w {
    margin-left: 50px;
    width: 300px;
    height: 40px;
}

.VAForm_layer__1OTmL {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 20px;
    border-radius: 3px;
}

.VAForm_inLayer__hMtwp {
    background-color: white;
    padding: 10px;
}

.VAForm_p_header__1hbzs {
    padding: 10px 0;
}

.VAForm_section__2yA90 {
    margin: 30px;
}

.VAForm_content__3ZlYu {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.VAForm_main_divider__3qUyi {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}

.VAForm_content_divider__3EA6h {
    margin: 5px 0;
}

.VAForm_free_table__14DO8 {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.VAForm_button__3Yt6U {
    width: 100%;
    border: none;
    background-color: #990000;
    color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 500;
    padding: 20px;
    font-size: 20px;
}


.VAForm_termsAndAgreements__eeu27 {
    width: 100%; 
    height: 70vh; 
    margin-bottom: 20px; 
    background-color: #EDEDED;
    border: none;
    resize: none;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500;
    padding: 10px;
}

.VAForm_termsAndAgreementsTitle__EAL-h {
    width: 100%; 
    margin-top: 20px; 
    background-color: #EDEDED;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 700;
    padding: 10px;
    font-size: 30px;
}

.VAForm_termsAndAgreements__eeu27:focus {
    border: none;
    outline: none;
}


.VAForm_info__MzuCZ {
    margin: 0px 0px 50px 0px;
    background-color: #505050;
    border-radius: 10px;
}

.VAForm_info_title__2iaTn {
    padding: 20px 20px 0 20px;
    color: white;
    font-size: 25px;
    font-weight: 600;
}

.VAForm_info_content__I9RxJ {
    padding: 20px;
    color: white;
    font-size: 13px;
    font-weight: 600;
}

.LTForm_leftdiv__38-Ss {
    float: left;
    width: 500px;

}

.LTForm_rightdiv__1NA4J {
    float: left;
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
}

/*div {*/
/*    color: black;*/
/*    background-color: rgb(223, 217, 217);*/
/*    height: 50px;*/
/*    font-size: 12px;*/
/*}*/

.LTForm_btn0__1e98P {
    margin-right: 50px;
    width: 300px;
    height: 40px;
}

.LTForm_btn1__2_Sfe {
    margin-left: 50px;
    width: 300px;
    height: 40px;
}

.LTForm_layer__GJN4U {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 20px;
    border-radius: 3px;
}

.LTForm_inLayer__1ERFx {
    background-color: white;
    padding: 10px;
}

.LTForm_p_header__2orxB {
    padding: 10px 0;
}

.LTForm_section__1QTXf {
    margin: 30px;
}

.LTForm_content__3oeYw {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.LTForm_main_divider__3TILe {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}

.LTForm_content_divider__1CQcH {
    margin: 5px 0;
}

.LTForm_free_table__2xFgL {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.LTForm_button__vjz9p {
    width: 100%;
    border: none;
    background-color: #990000;
    color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 500;
    padding: 20px;
    font-size: 20px;
    /* margin: 5px; */
}

.LTForm_info__HC4j2 {
    margin: 0px 30px 50px 30px;
    background-color: #505050;
    border-radius: 10px;
}

.LTForm_info_title__3Bl7L {
    padding: 20px 20px 0 20px;
    color: white;
    font-size: 25px;
    font-weight: 600;
}

.LTForm_info_content__3CAnM {
    padding: 20px;
    color: white;
    font-size: 13px;
    font-weight: 600;
}

.LTForm_termsAndAgreementsTitle__G1h3c {
    width: 100%; 
    margin-top: 20px; 
    background-color: #EDEDED;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 700;
    padding: 10px;
    font-size: 30px;
}

.LTForm_termsAndAgreements__1d0MP:focus {
    border: none;
    outline: none;
}

.LTForm_termsAndAgreements__1d0MP {
    width: 100%; 
    height: 70vh; 
    margin-bottom: 20px; 
    background-color: #EDEDED;
    border: none;
    resize: none;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500;
    padding: 10px;
}
body {
    font-family: 'Noto Sans KR', sans-serif;
}

.PaymentForm_leftdiv__2AN1V {
    float: left;
    width: 500px;

}

.PaymentForm_rightdiv__1djM8 {
    float: left;
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
}

/*div {*/
/*    color: black;*/
/*    background-color: rgb(223, 217, 217);*/
/*    height: 50px;*/
/*    font-size: 12px;*/
/*}*/

.PaymentForm_btn0__1XCiL {
    margin-right: 50px;
    width: 300px;
    height: 40px;
}

.PaymentForm_btn1__rzyIX {
    margin-left: 50px;
    width: 300px;
    height: 40px;
}

.PaymentForm_layer__yJ-qf {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 20px;
    border-radius: 3px;
}

.PaymentForm_p_header__kL1Mw {
    padding: 10px 0;
}

.PaymentForm_section__36gEK {
    margin: 30px;
}

.PaymentForm_content__2y-3z {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.PaymentForm_main_divider__2PmqA {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}

.PaymentForm_content_divider__sbVw8 {
    margin: 5px 0;
}

.PaymentForm_free_table__3gPb4 {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.PaymentForm_button__uOu25 {
    background-color: #EDEDED;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 6px 0;
}

.PaymentForm_selectedButton__34g_N {
    background-color: #990000;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 6px 0;
}

.PaymentForm_payment_form_container__2TMOn {
    width: 500px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f7fa;
}

.PaymentForm_payment_amount__1EKJ4 {
    display: flex;
    justify-content: space-between;
    padding: 40px 30px 70px 30px;
    background-color: #12213b;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.PaymentForm_payment_amount_tit__-dxiT {
    font-size: 25px;
    color: #ffffff;
    font-weight: 700;
    line-height: 52px;
}

.PaymentForm_payment_amount_num__ks8VB {
    font-size: 25px;
    color: #ffffff;
}

/* TOGGLE STYLING */
.PaymentForm_toggle__1oqtc {
    padding-bottom: 50px;
    box-sizing: border-box;
    font-size: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
    margin-top: -30px;
}
.PaymentForm_toggle__1oqtc input {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
}
.PaymentForm_toggle__1oqtc input + label {
    margin: 0;
    padding: 1.25rem 2rem;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border: solid 1px #ddd;
    background-color: #fff;
    font-size: 16px;
    line-height: 140%;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    transition: border-color 0.15s ease-out, color 0.25s ease-out, background-color 0.15s ease-out, box-shadow 0.15s ease-out;
    cursor: pointer;
}
.PaymentForm_toggle__1oqtc input + label:first-of-type {
    border-radius: 6px 0 0 6px;
    border-right: none;
}
.PaymentForm_toggle__1oqtc input + label:last-of-type {
    border-radius: 0 6px 6px 0;
    border-left: none;
}
.PaymentForm_toggle__1oqtc input:hover + label {
    border-color: #ddd;
}
.PaymentForm_toggle__1oqtc input:checked + label {
    background-color: #607181;
    color: #fff;
    box-shadow: 0 0 10px rgba(96,113,129, .5);
    border-color: #607181;
}
.PaymentForm_toggle__1oqtc input:focus + label {
    outline: 0px transparent;
}
@media (max-width: 800px) {
    .PaymentForm_toggle__1oqtc input + label {
        padding: 0.75rem 0.25rem;
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
   }

   .PaymentForm_payment_form_container__2TMOn {
    width: 100%;
    margin: 0px;
    border-radius: 0px;
    box-shadow: none;
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f7fa;
    }
    .PaymentForm_payment_amount__1EKJ4 {
        display: flex;
        justify-content: space-between;
        padding: 40px 30px 70px 30px;
        background-color: #181a29;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}
body {
    font-family: 'Noto Sans KR', sans-serif !important;
}

.Admin_list_container__2HTlJ {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    height: 595px;
    padding: 30px;
}

.Admin_title__r7mra {
    color: #323232;
    font-size: 25px;
    font-weight: bold;
}

.Admin_sub_title__2Vc_K {
    font-weight: 600;
}

.Admin_deleteButton__3i0sw {
    align-items: center;
    background-color: #fe4806;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.Admin_button__3VmTe {
    align-items: center;
    background-color: #0A66C2;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.Admin_button__3VmTe:hover {
    background-color: #16437E;
    color: #ffffff;
}

.Admin_button__3VmTe:active {
    background: #09223b;
    color: rgb(255, 255, 255, .7);
}

.Admin_button__3VmTe:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}

.Admin_button_cancel__g1YSa {
    align-items: center;
    background-color: #FF5959;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.Admin_button_cancel__g1YSa:hover {
    background-color: #9B0000;
    color: #ffffff;
}

.Admin_button_cancel__g1YSa:active {
    background: #9B0000;
    color: rgb(255, 255, 255, .7);
}

.Admin_button_cancel__g1YSa:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}

.Admin_coupon_button__1Sy66 {
    align-items: center;
    background-color: #019267;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    width: 120px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    position: absolute;
    left: calc(50% - 60px);
}

.Admin_coupon_button__1Sy66:hover {
    background-color: #00694a;
    color: #ffffff;
}

.Admin_coupon_button__1Sy66:active {
    background: #00694a;
    color: rgb(255, 255, 255, .7);
}

.Admin_coupon_button__1Sy66:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}

.Admin_refund_box__2_h2m {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    background-color: #19bc28;
    height: 100px;
    margin: 10px 15px;
    padding: 15px 0;
}

.Admin_refund_info__1gl5F {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #b0b1b6;
    margin: 0px 15px;
}

.Admin_refund_info_title__11JoL {
    color: #b0b1b6;
    font-weight: 400;
}

.Admin_refund_info_content__he1uW {
    font-weight: 500;
}

.Admin_inquiry_body__16iaN {
    background-color: white;
    height: 100%;
    padding: 20px;
}

.Admin_inquirer_time__2HHHo {
    color: gray;
    font-size: 12px;
}

.Admin_inquirer_icon__51fT5 {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #f95ca1;
    border-radius: 100px;
}

.Admin_inquirer_name__3pYGw {
    display: inline-block;
    height: 50px;
    margin-left: 10px;
}

.Admin_inquiry_body_title__34fft {
    color: #171d4b;
    font-size: 20px;
    font-weight: bold;
    margin-left: 50px;
}

.Admin_inquiry_body_question__3ZhmV {
    color: #383d53;
    font-weight: 500;
    margin-top: 20px;
}

.Admin_inquiry_body_answer__1snPt {
    border: none;
    background-color: #F5F5F5;
    border-radius: 10px;
    height: 300px !important;
    resize: none;
    outline: none;
    white-space: pre-wrap;
}

.Admin_inquiry_body_answer__1snPt:focus {
    outline: none !important;
    border: none;
}


.Admin_answer_icon__1kPh2 {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #7c71f9;
    border-radius: 100px;
    margin-top: 40px;
}

.Admin_code_button_container__1fnry {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.Admin_code_button__34FeV {
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #066bff;
    border: 0;
    border-radius: 10px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.Admin_code_container__3zUqb {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.Admin_code__3ZWmT.Admin_hidden__2mcYu {
    opacity: 0;
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    transition: all 1s;
}

.Admin_code__3ZWmT {
    background-color: #282c34;
    color: white;
    font-weight: bold;
    padding: 10px 30px;
    width: 450px;
    border-radius: 20px;
    font-size: 30px;
    letter-spacing: 3px;
    display: flex;
    justify-content: space-between;
    transition: all 1s;
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
}

.Admin_clipboard__m4oUx {
    font-size: 20px;
    color: white;
    transition: all 0.3s;
}

.Admin_clipboard__m4oUx.Admin_copied__2aIZ_ {
    font-size: 20px;
    color: red;
    transition: all 1s;
}


.Admin_p-5__3PrWj {
    padding: 1.5rem !important;
}

.Admin_auto_button__2jy6y {
    align-items: center;
    background-color: #019267;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    width: 120px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.Admin_battle_button__2GjHN {
    align-items: center;
    background-color: #019267;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    width: 150px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    position: absolute;
    left: calc(50% - 60px);
}

@media (min-width: 992px) {
    .Admin_col-lg-12__20cEI {
        flex: 0 0 auto;
        width: 100%;
    }
}


@media (min-width: 1205px) {
    .Admin_col-xl-8__KeCD6 {
        flex: 0 0 auto;
        width: 66.66666666%;
    }

    .Admin_col-xl-6__2pjED {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media only screen and (min-width: 1950px) {
    .Admin_infoHeader__2litR {
        height: 200px;
        width: 400px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoHeader__2litR .Admin_top__1q4E0 {
        text-align: center;
        font-size: 100px;
        font-weight: bold;
    }

    .Admin_infoHeader__2litR .Admin_bottom__t9p0E {
        text-align: center;
        font-weight: bold;
    }

    .Admin_infoButtonHeader__1OH2c {
        height: 200px;
        width: 400px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Admin_adminButton__3B9_z {
        width: 60%;
        text-align: center;
        height: 50px;
        margin: 5px 0 5px 0;
        line-height: 50px;
        border-radius: 30px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }

    .Admin_infoTitle__19qZM {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoBody__2CeFL {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        padding: 20px;
    }

    .Admin_functionContainer1__3DhPK {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        margin-top: 70px;
    }

    .Admin_functionContainer2__1cUYS {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        padding: 70px;
        margin-top: 70px;
    }

    .Admin_functionContainer3__10FVz {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
    }
}

@media only screen and (max-width: 1950px) {
    .Admin_infoHeader__2litR {
        height: 150px;
        width: 300px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoHeader__2litR .Admin_top__1q4E0 {
        text-align: center;
        font-size: 70px;
        font-weight: bold;
    }

    .Admin_infoHeader__2litR .Admin_bottom__t9p0E {
        text-align: center;
        font-weight: bold;
    }

    .Admin_infoButtonHeader__1OH2c {
        height: 150px;
        width: 300px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Admin_adminButton__3B9_z {
        width: 50%;
        text-align: center;
        height: 40px;
        margin: 3px 0 3px 0;
        line-height: 40px;
        border-radius: 30px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }

    .Admin_infoTitle__19qZM {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoBody__2CeFL {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .Admin_functionContainer1__3DhPK {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        margin-top: 70px;
    }

    .Admin_functionContainer2__1cUYS {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        padding: 70px;
        margin-top: 70px;
    }

    .Admin_functionContainer3__10FVz {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
    }
}

@media only screen and (max-width: 1460px) {
    .Admin_infoHeader__2litR {
        height: 100px;
        width: 200px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoHeader__2litR .Admin_top__1q4E0 {
        text-align: center;
        font-size: 45px;
        font-weight: bold;
    }

    .Admin_infoHeader__2litR .Admin_bottom__t9p0E {
        text-align: center;
        font-weight: bold;
    }

    .Admin_infoButtonHeader__1OH2c {
        height: 100px;
        width: 200px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .Admin_adminButton__3B9_z {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 50px;
        border-radius: 13px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }

    .Admin_infoTitle__19qZM {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoBody__2CeFL {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .Admin_functionContainer1__3DhPK {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        margin-top: 70px;
    }

    .Admin_functionContainer2__1cUYS {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        padding: 50px;
        margin-top: 70px;
    }

    .Admin_functionContainer3__10FVz {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
    }
}

@media only screen and (max-width: 1205px) {
    .Admin_functionContainer1__3DhPK {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer2__1cUYS {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        padding: 70px;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer3__10FVz {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
    }
}

@media only screen and (max-width: 680px) {
    .Admin_infoHeader__2litR {
        height: 80px;
        width: 160px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
    }

    .Admin_infoHeader__2litR .Admin_top__1q4E0 {
        text-align: center;
        font-size: 35px;
        font-weight: bold;
    }

    .Admin_infoHeader__2litR .Admin_bottom__t9p0E {
        text-align: center;
        font-weight: bold;
    }

    .Admin_infoButtonHeader__1OH2c {
        height: 80px;
        width: 160px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .Admin_adminButton__3B9_z {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 15px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
        padding: 8px 5px 0 5px;
    }

    .Admin_infoTitle__19qZM {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoBody__2CeFL {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .Admin_functionContainer1__3DhPK {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer2__1cUYS {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        padding: 80px;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer2__1cUYS .Admin_adminButton__3B9_z {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 30px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
        padding: 8px 5px 0 5px;
    }

    .Admin_functionContainer3__10FVz {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
    }
}

@media only screen and (max-width: 576px) {
    .Admin_infoHeader__2litR {
        height: 100px;
        width: 100%;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .Admin_infoHeader__2litR .Admin_top__1q4E0 {
        text-align: center;
        padding: 0px;
        font-size: 70px;
        font-weight: bold;
        margin-left: 80px;
        line-height: 88px;
    }

    .Admin_infoHeader__2litR .Admin_bottom__t9p0E {
        text-align: center;
        font-weight: bold;
        font-size: 50px;
        padding: 10px 0 10px 0;
        margin-right: 80px;
    }

    .Admin_infoButtonHeader__1OH2c {
        height: 100px;
        width: 100%;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .Admin_adminButton__3B9_z {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 30px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
        padding: 8px 5px 0 5px;
    }

    .Admin_infoTitle__19qZM {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoBody__2CeFL {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .Admin_functionContainer1__3DhPK {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer2__1cUYS {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
        padding: 60px;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer3__10FVz {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__vXXJC 1s;
                animation: Admin_push__vXXJC 1s;
    }
}

@-webkit-keyframes Admin_push__vXXJC {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
    }
}

@keyframes Admin_push__vXXJC {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
    }
}

.Admin_signUpContainer__3GJS6 {
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: 10px;
    padding-bottom: 30px;
}

.Admin_xpercent__3UMdd {
    font-family: "Poppins", sans-serif;
    color: #333;
    padding-top: 10px;
    padding-left: 50px;
    display: flex;
    align-items: center;
}

.Admin_xpercent__3UMdd img {
    height: 30px;
    width: 30px;
}

.Admin_xpercent__3UMdd span {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .4em;
    cursor: pointer;
}

.Admin_signUpTitle__K0lPw {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 30px;
    font-weight: 900;
    color: #333;
    letter-spacing: 0.2em;
    padding-left: 50px;
    margin-bottom: 30px;
}

.Admin_selectModel__3N9fE {
    font-family: 'Noto Sans KR', sans-serif;
}

.Admin_signUpInput__3r81- {
    width: 100%;
    padding: 10px 20px 10px 20px;
    font-weight: 600;
}

.Admin_signUpInput__3r81-::-webkit-input-placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500
}

.Admin_signUpInput__3r81-::placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500
}

.Admin_signUpInput__3r81- input::-webkit-input-placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500
}

.Admin_signUpInput__3r81- input::placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500
}

.Admin_signUpButton__3cYiC {
    background-color: #0041ed;
    font-family: 'Noto Sans KR', sans-serif;
    border: none;
    padding: 10px 20px;
    margin: 0 50px;
    text-align: center;
    color: white;
    width: 100%
}

@media only screen and (max-width: 1516px) {
    .Admin_xpercent__3UMdd img {
        height: 25px;
        width: 25px;
    }

    .Admin_xpercent__3UMdd span {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }
}

/* @media only screen and (max-width: 1140px) {
    .xpercent img {
        height: 20px;
        width: 20px;
    }
    
    .xpercent span {
        font-size: 25px; 
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }
} */

@media only screen and (max-width: 1205px) {
    .Admin_xpercent__3UMdd img {
        height: 30px;
        width: 30px;
    }

    .Admin_xpercent__3UMdd span {
        font-size: 40px;
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }
}

@media only screen and (max-width: 480px) {
    .Admin_xpercent__3UMdd img {
        height: 20px;
        width: 20px;
    }

    .Admin_xpercent__3UMdd span {
        font-size: 25px;
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }

    .Admin_infoHeader__2litR .Admin_top__1q4E0 {
        text-align: center;
        padding: 0px;
        font-size: 60px;
        font-weight: bold;
        margin-left: 80px;
        line-height: 88px;
    }

    .Admin_infoHeader__2litR .Admin_bottom__t9p0E {
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        padding: 10px 0 10px 0;
        margin-right: 80px;
    }
}

.Admin_date_container__3Xn7u {
    background-color: #eaf9f1;
    padding: 7px 10px;
    border-radius: 10px;
}

.Admin_active_date__3mer9 {
    font-weight: bold;
    color: #46c795;
    font-size: 15px;
    margin: 0px 3px;
}

.Admin_inactive_date__29MFD {
    color: #b2beba;
    font-weight: bold;
    font-size: 15px;
    margin: 0px 3px;
}

.Admin_change_date__ws1tI {
    cursor: pointer
}

input[type="checkbox"] {
    visibility: hidden;
    display: none;
}

input[type="checkbox"]:checked+.Admin_selectModel__3N9fE {
    /* transform: rotate(360deg); */
    background-color: #000;
}

input[type="checkbox"]:checked+.Admin_selectModel__3N9fE:before {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    background-color: #fff;
}

.Admin_selectModel__3N9fE {
    display: flex;
    width: 60px;
    height: 30px;
    border: 3px solid;
    border-radius: 99em;
    position: relative;
    transition: -webkit-transform 0.75s ease-in-out;
    transition: transform 0.75s ease-in-out;
    transition: transform 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    cursor: pointer;
}

.Admin_selectModel__3N9fE:before {
    transition: -webkit-transform 0.75s ease;
    transition: transform 0.75s ease;
    transition: transform 0.75s ease, -webkit-transform 0.75s ease;
    /* transition-delay: 0.5s; */
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #000;
    border-radius: 50%;
    top: 5px;
    left: 5px;
}

.Admin_toggleOff__qVNk2:before {
    content: "OFF";
    left: 24px;
    position: absolute;
    top: 2px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 600;
    opacity: 1;
    transition: opacity 0.75s ease;
}

.Admin_toggleOn__3gCCd:after {
    content: "ON";
    right: 28px;
    position: absolute;
    color: white;
    top: 2px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 600;
    opacity: 0;
    transition: opacity 0.75s ease;
}

input[type="checkbox"]:checked+.Admin_selectModel__3N9fE .Admin_toggleOn__3gCCd:after {
    opacity: 1;
}

input[type="checkbox"]:checked+.Admin_selectModel__3N9fE .Admin_toggleOff__qVNk2:before {
    opacity: 0;
}

.Admin_selectSector__c4w3v label {
    display: inline-block;
    background-color: white;
    color: #adadad;
    border-radius: 10px;
    white-space: nowrap;
    margin: 3px 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s;
    padding: 2px 6px;
    cursor: pointer;
    margin: 1px;
}

.Admin_selectSector__c4w3v input[type="checkbox"]:checked+.Admin_kospi__3WAM6 {
    background-color: #ff533e;
    color: #fff;
    transition: all .2s;
}

.Admin_selectSector__c4w3v input[type="checkbox"]:checked+.Admin_kosdaq__3BGcE {
    background-color: #ff533e;
    color: #fff;
    transition: all .2s;
}

.Admin_selectSector__c4w3v input[type="checkbox"] {
    display: absolute;
}

.Admin_selectSector__c4w3v input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.Admin_selectSector__c4w3v input[type="checkbox"]:focus+label {
    border: 2px solid #e9a1ff;
}

.Admin_inputNotChanged__1e8mh input {
    color: black !important;
}

.Admin_inputChanged__1edwb input {
    color: blue !important;
}
.wrapper {
    max-width: 1024px;
    justify-content: center;
}

.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.inLayer {
    background-color: white;
    padding: 10px 0;
}

.button {
    margin-top: 10px;
    width: 100%;
    height: 40px;
}
.select-form {
    margin: 10px auto;
    text-align: center;
}

.date-select {
    background-color: rgba(0, 0, 0, 0);
    color: #2f2f2f;
    border-radius: 15px;
    height: 30px;
}

.date {
    width: 100%;
    text-align: center;
    font-size: 25px;
    vertical-align: middle;

    margin-top: 10px;
}

.notification {
    margin: 10px auto;
    font-size: 12px;
}
.vectorBackground {
    background-image: url(/static/media/vectorBackground.ca94e50c.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 300px;
    width: 30%;
    border-radius: 10px;
}

.vectorContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.select-form {
    margin: 10px auto;
    text-align: center;
}

.date-select {
    background-color: rgba(0, 0, 0, 0);
    color: #2f2f2f;
    border-radius: 15px;
    height: 30px;
}

.date {
    width: 100%;
    text-align: center;
    font-size: 25px;
    vertical-align: middle;

    margin-top: 10px;
}

.notification {
    margin: 10px auto;
    font-size: 12px;
}

.smallBox {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 3px 6px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.vectorIcon {
    font-size: 50px !important;
    color: white;
}

.vectorPerc {
    font-size: 80px;
    font-weight: bold;
    color: white;
}

.vectorTitle {
    color: #b9bff4;
    font-size: 30px;
}

@media only screen and (max-width: 600px) {
    .vectorContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
    }

    .vectorBackground {
        height: 200px;
        width: 90%;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    .vectorIcon {
        font-size: 30px !important;
        color: white;
    }

    .vectorPerc {
        font-size: 50px;
        font-weight: bold;
        color: white;
    }

    .vectorTitle {
        color: #b9bff4;
        font-size: 20px;
    }
}
.AutoTop5_layer__2yUMd {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}
body {
    font-family: 'Noto Sans KR', sans-serif;
}

.PaymentSuccess_payment_form_container__2pgwD {
    width: 500px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f7fa;
}

.PaymentSuccess_go_to_container__2FYkF {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.PaymentSuccess_go_to_icon__1vqjh:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}

.PaymentSuccess_icon__2Ddo4 {
    font-size: 50px; 
    margin-right: 20px;
    color: #52c41a;
}

.PaymentSuccess_text___BULS {
    font-size: 30px;
}

@media (max-width: 800px) {
    .PaymentSuccess_payment_form_container__2pgwD {
        width: 90%;
        margin: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-left: auto;
        margin-right: auto;
        background-color: #f6f7fa;
    }

    .PaymentSuccess_icon__2Ddo4 {
        font-size: 40px;
    }

    .PaymentSuccess_text___BULS {
        font-size: 22px;
    }
}
body {
    font-family: 'Noto Sans KR', sans-serif;
}

.PaymentFail_payment_form_container__BUYBH {
    width: 500px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f7fa;
}

.PaymentFail_go_to_container__2O8Dz {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.PaymentFail_go_to_icon__1W8YB:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}


.PaymentFail_icon__1zNjP {
    font-size: 50px; 
    margin-right: 20px;
    color: #52c41a;
}

.PaymentFail_text__br5MU {
    font-size: 30px;
}

@media (max-width: 800px) {
    .PaymentFail_payment_form_container__BUYBH {
        width: 90%;
        margin: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-left: auto;
        margin-right: auto;
        background-color: #f6f7fa;
    }

    .PaymentFail_icon__1zNjP {
        font-size: 40px;
    }

    .PaymentFail_text__br5MU {
        font-size: 22px;
    }
}
.AutoTransaction_autoTransactionImage__3z-fR {
    width: 100%;
}
.Simulation_simulationTransactionImage__PO6jV {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

@media only screen and (max-width: 500px)
{
    .Simulation_simulationTransactionImage__PO6jV {
        width: 100%;
    }
}
.SimulationInfo_wrapper__2q9EM {
    max-width: 1024px;
    justify-content: center;
}
.SimulationInfo_layer__1f8Oi {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.SimulationInfo_inLayer__3rsI3 {
    background-color: white;
    padding: 10px 0;
}

.SimulationInfo_smallBox__3ADiK {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 0 6px;
    width: 100%;
}

.SimulationInfo_field__eFbis {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}


.SimulationInfo_field__eFbis input {
    background-color: #EDEDED !important;
    color: #181818;
}
.SimulationInfo_field__eFbis span span {
    color: #030303;
}
.SimulationInfo_field__eFbis::-webkit-input-placeholder {
    color: grey !important;
}
.SimulationInfo_field__eFbis::placeholder {
    color: grey !important;
}
.SimulationInfo_field__eFbis input::-webkit-input-placeholder {
    color: grey;
}
.SimulationInfo_field__eFbis input::placeholder {
    color: grey;
}

.SimulationInfo_button__166HK {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    padding: 6px;
}
.SimulationInfo_copy__2P5ta div {
    margin-top: 10px;
    width: 100%;
}

.SimulationInfo_passwordModal__1cTX1 {
    padding: 0 !important;
}
.Announcement_wrapper__3-Bq7 {
    max-width: 1024px;
    justify-content: center;
}

.Announcement_layer__2su-s {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.Announcement_title__QoV4Z {
    margin: 30px 0;
}

.Announcement_section__1IGDU {
    margin: 30px;
}

.Announcement_image__3oJ1t img {
    max-width: 70%;
    height: auto;
    display: block;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    margin: 0px auto;
}

.Announcement_button__j8uBa {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    width: 20%;
    height: 40px;
}

/* .post-view-wrapper {
    width: 60%;
    margin: 0 auto;
  }
  
  .post-view-go-list-btn {
    border: 0;
    padding: 10px;
    background-color: #ffd9d9;
  }
  
  .post-view-row {
    margin: 10px 0;
    display: flex;
  }
  
  .post-view-row > label:first-child {
    margin: 10px 0;
    width: 30%;
    font-weight: bold;
  }
  
  .post-view-row > *:nth-child(2) {
    margin: 10px 0;
    width: 70%;
  } */
.ViewList_button__1vPUx {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    width: 20%;
    height: 40px;
}
.common-table {
    width: 80%;
    margin: 0 auto;
    /* text-align: center; */
    text-align: left;
    border-spacing: 0;
  }
  
  .common-table-header-column {
    border-bottom: 2px solid #e8e8e8;
    padding: 0;
    font-size: 16px;
    padding: 10px 5px;
    font-weight: bold;
  }

  .common-table-header-column:nth-child(1) {
    width: 70px;
    text-align: center;
  }
  .common-table-header-column:nth-child(2) {
    width: auto;
  }
  .common-table-header-column:nth-child(3) {
    width: 100px;
  }

  .common-table-column:nth-child(1) {
    text-align: center;
  }
  
  /* .common-table-row:hover {
    background-color: #eceaea;
    cursor: pointer;
  } */
  
  .common-table-column {
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 5px;
  }
.ViewPost_button__272Sa {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    width: 20%;
    height: 40px;
}
.WritePost_wrapper___NJqW {
    max-width: 1024px;
    justify-content: center;
}
.WritePost_layer__2zFqf {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.WritePost_inLayer__2_eS7 {
    background-color: white;
    padding: 10px 0;
}

.WritePost_smallBox__Cs0Ir {
    background-color: #EDEDED;
    border: #DEDEDE;
    height: 40px;
    color: black;
    padding: 0 6px;
    width: 100%;
}

.WritePost_field__LLjEd {
    background-color: #EDEDED !important;
    border: 0;
    color: #1a1a1a;
    height: 40px;

}


.WritePost_field__LLjEd input {
    background-color: #EDEDED !important;
    color: #181818;
}
.WritePost_field__LLjEd span span {
    color: #030303;
}
.WritePost_field__LLjEd::-webkit-input-placeholder {
    color: grey !important;
}
.WritePost_field__LLjEd::placeholder {
    color: grey !important;
}
.WritePost_field__LLjEd input::-webkit-input-placeholder {
    color: grey;
}
.WritePost_field__LLjEd input::placeholder {
    color: grey;
}

.WritePost_button__3wyDI {
    width: 100%;
    height: 40px;
}
body {
    font-family: 'Noto Sans KR', sans-serif !important;
}

.Admin_list_container__1_1Rw {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    height: 595px;
    padding: 30px;
}

.Admin_title__2rAqi {
    color: #323232;
    font-size: 25px;
    font-weight: bold;
}

.Admin_sub_title__KfXfw {
    font-weight: 600;
}

.Admin_deleteButton__6tmO_ {
    align-items: center;
    background-color: #fe4806;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}
.Admin_active_selected_model__3GaSO{
    background-color: #1d3a57!important;
    color: #ffffff;
}
.Admin_deactive_selected_model__2Zlwp{
    background-color: #0A66C2!important;
     color: #ffffff;
}
.Admin_button__3_gI7 {
    margin: 5px 4px;
    align-items: center;
    background-color: #0A66C2;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.Admin_button__3_gI7:hover {
    background-color: #16437E;
    color: #ffffff;
}

.Admin_button__3_gI7:active {
    background: #09223b;
    color: rgb(255, 255, 255, .7);
}

.Admin_button__3_gI7:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}

.Admin_button_cancel__3Lcme {
    align-items: center;
    background-color: #FF5959;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.Admin_button_cancel__3Lcme:hover {
    background-color: #9B0000;
    color: #ffffff;
}

.Admin_button_cancel__3Lcme:active {
    background: #9B0000;
    color: rgb(255, 255, 255, .7);
}

.Admin_button_cancel__3Lcme:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}

.Admin_coupon_button__16hm9 {
    align-items: center;
    background-color: #019267;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    width: 120px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    position: absolute;
    left: calc(50% - 60px);
}

.Admin_coupon_button__16hm9:hover {
    background-color: #00694a;
    color: #ffffff;
}

.Admin_coupon_button__16hm9:active {
    background: #00694a;
    color: rgb(255, 255, 255, .7);
}

.Admin_coupon_button__16hm9:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}

.Admin_refund_box__nkwXs {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    background-color: #19bc28;
    height: 100px;
    margin: 10px 15px;
    padding: 15px 0;
}

.Admin_refund_info__MDGAV {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #b0b1b6;
    margin: 0px 15px;
}

.Admin_refund_info_title__1zt6k {
    color: #b0b1b6;
    font-weight: 400;
}

.Admin_refund_info_content__1zYBg {
    font-weight: 500;
}

.Admin_inquiry_body__1j4KO {
    background-color: white;
    height: 100%;
    padding: 20px;
}

.Admin_inquirer_time__1aG0z {
    color: gray;
    font-size: 12px;
}

.Admin_inquirer_icon__3eP4H {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #f95ca1;
    border-radius: 100px;
}

.Admin_inquirer_name__2tKSc {
    display: inline-block;
    height: 50px;
    margin-left: 10px;
}

.Admin_inquiry_body_title__1cbC8 {
    color: #171d4b;
    font-size: 20px;
    font-weight: bold;
    margin-left: 50px;
}

.Admin_inquiry_body_question__rRngp {
    color: #383d53;
    font-weight: 500;
    margin-top: 20px;
}

.Admin_inquiry_body_answer___9LgI {
    border: none;
    background-color: #F5F5F5;
    border-radius: 10px;
    height: 300px !important;
    resize: none;
    outline: none;
    white-space: pre-wrap;
}

.Admin_inquiry_body_answer___9LgI:focus {
    outline: none !important;
    border: none;
}


.Admin_answer_icon__Fp1dg {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #7c71f9;
    border-radius: 100px;
    margin-top: 40px;
}

.Admin_code_button_container__yQ8Uc {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.Admin_code_button__2LyaL {
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #066bff;
    border: 0;
    border-radius: 10px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.Admin_code_container__eKPMW {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.Admin_code__2IRiy.Admin_hidden__3bS_y {
    opacity: 0;
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    transition: all 1s;
}

.Admin_code__2IRiy {
    background-color: #282c34;
    color: white;
    font-weight: bold;
    padding: 10px 30px;
    width: 450px;
    border-radius: 20px;
    font-size: 30px;
    letter-spacing: 3px;
    display: flex;
    justify-content: space-between;
    transition: all 1s;
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
}

.Admin_clipboard__3eLhv {
    font-size: 20px;
    color: white;
    transition: all 0.3s;
}

.Admin_clipboard__3eLhv.Admin_copied__A6Nzm {
    font-size: 20px;
    color: red;
    transition: all 1s;
}


.Admin_p-5__2440t {
    padding: 1.5rem !important;
}

.Admin_auto_button__2mauS {
    align-items: center;
    background-color: #019267;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    width: 120px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.Admin_battle_button__2ntcH {
    align-items: center;
    background-color: #019267;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    width: 150px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    position: absolute;
    left: calc(50% - 60px);
}

@media (min-width: 992px) {
    .Admin_col-lg-12__2dgSa {
        flex: 0 0 auto;
        width: 100%;
    }
}


@media (min-width: 1205px) {
    .Admin_col-xl-8__2M-CI {
        flex: 0 0 auto;
        width: 66.66666666%;
    }

    .Admin_col-xl-6__avQ4a {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media only screen and (min-width: 1950px) {
    .Admin_infoHeader__1Ap7D {
        height: 200px;
        width: 400px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoHeader__1Ap7D .Admin_top__1cGq9 {
        text-align: center;
        font-size: 100px;
        font-weight: bold;
    }

    .Admin_infoHeader__1Ap7D .Admin_bottom__H3Foa {
        text-align: center;
        font-weight: bold;
    }

    .Admin_infoButtonHeader__3pL5c {
        height: 200px;
        width: 400px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Admin_adminButton__18rTD {
        width: 60%;
        text-align: center;
        height: 50px;
        margin: 5px 0 5px 0;
        line-height: 50px;
        border-radius: 30px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }

    .Admin_infoTitle__230TD {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoBody__257RU {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        padding: 20px;
    }

    .Admin_functionContainer1__2Uqlt {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        margin-top: 70px;
    }

    .Admin_functionContainer2__1CGhf {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        padding: 70px;
        margin-top: 70px;
    }

    .Admin_functionContainer3__3u1lf {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
    }
}

@media only screen and (max-width: 1950px) {
    .Admin_infoHeader__1Ap7D {
        height: 150px;
        width: 300px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoHeader__1Ap7D .Admin_top__1cGq9 {
        text-align: center;
        font-size: 70px;
        font-weight: bold;
    }

    .Admin_infoHeader__1Ap7D .Admin_bottom__H3Foa {
        text-align: center;
        font-weight: bold;
    }

    .Admin_infoButtonHeader__3pL5c {
        height: 150px;
        width: 300px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Admin_adminButton__18rTD {
        width: 50%;
        text-align: center;
        height: 40px;
        margin: 3px 0 3px 0;
        line-height: 40px;
        border-radius: 30px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }

    .Admin_infoTitle__230TD {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoBody__257RU {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .Admin_functionContainer1__2Uqlt {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        margin-top: 70px;
    }

    .Admin_functionContainer2__1CGhf {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        padding: 70px;
        margin-top: 70px;
    }

    .Admin_functionContainer3__3u1lf {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
    }
}

@media only screen and (max-width: 1460px) {
    .Admin_infoHeader__1Ap7D {
        height: 100px;
        width: 200px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoHeader__1Ap7D .Admin_top__1cGq9 {
        text-align: center;
        font-size: 45px;
        font-weight: bold;
    }

    .Admin_infoHeader__1Ap7D .Admin_bottom__H3Foa {
        text-align: center;
        font-weight: bold;
    }

    .Admin_infoButtonHeader__3pL5c {
        height: 100px;
        width: 200px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .Admin_adminButton__18rTD {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 50px;
        border-radius: 13px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }

    .Admin_infoTitle__230TD {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoBody__257RU {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .Admin_functionContainer1__2Uqlt {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        margin-top: 70px;
    }

    .Admin_functionContainer2__1CGhf {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        padding: 50px;
        margin-top: 70px;
    }

    .Admin_functionContainer3__3u1lf {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
    }
}

@media only screen and (max-width: 1205px) {
    .Admin_functionContainer1__2Uqlt {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer2__1CGhf {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        padding: 70px;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer3__3u1lf {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
    }
}

@media only screen and (max-width: 680px) {
    .Admin_infoHeader__1Ap7D {
        height: 80px;
        width: 160px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
    }

    .Admin_infoHeader__1Ap7D .Admin_top__1cGq9 {
        text-align: center;
        font-size: 35px;
        font-weight: bold;
    }

    .Admin_infoHeader__1Ap7D .Admin_bottom__H3Foa {
        text-align: center;
        font-weight: bold;
    }

    .Admin_infoButtonHeader__3pL5c {
        height: 80px;
        width: 160px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .Admin_adminButton__18rTD {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 15px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
        padding: 8px 5px 0 5px;
    }

    .Admin_infoTitle__230TD {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoBody__257RU {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .Admin_functionContainer1__2Uqlt {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer2__1CGhf {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        padding: 80px;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer2__1CGhf .Admin_adminButton__18rTD {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 30px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
        padding: 8px 5px 0 5px;
    }

    .Admin_functionContainer3__3u1lf {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
    }
}

@media only screen and (max-width: 576px) {
    .Admin_infoHeader__1Ap7D {
        height: 100px;
        width: 100%;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .Admin_infoHeader__1Ap7D .Admin_top__1cGq9 {
        text-align: center;
        padding: 0px;
        font-size: 70px;
        font-weight: bold;
        margin-left: 80px;
        line-height: 88px;
    }

    .Admin_infoHeader__1Ap7D .Admin_bottom__H3Foa {
        text-align: center;
        font-weight: bold;
        font-size: 50px;
        padding: 10px 0 10px 0;
        margin-right: 80px;
    }

    .Admin_infoButtonHeader__3pL5c {
        height: 100px;
        width: 100%;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .Admin_adminButton__18rTD {
        width: 30%;
        text-align: center;
        height: 50px;
        margin: 0px 0px 0px 0px;
        line-height: 30px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: white;
        font-weight: bold;
        padding: 8px 5px 0 5px;
    }

    .Admin_infoTitle__230TD {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .Admin_infoBody__257RU {
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .Admin_functionContainer1__2Uqlt {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer2__1CGhf {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        width: 100%;
        height: 270px;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
        padding: 60px;
        margin-top: 0;
        margin-bottom: 100px;
    }

    .Admin_functionContainer3__3u1lf {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 100%;
        border-radius: 20px;
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
        -webkit-animation: Admin_push__2nd7O 1s;
                animation: Admin_push__2nd7O 1s;
    }
}

@-webkit-keyframes Admin_push__2nd7O {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
    }
}

@keyframes Admin_push__2nd7O {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
    }
}

.Admin_signUpContainer__35Ihd {
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: 10px;
    padding-bottom: 30px;
}

.Admin_xpercent__3F8nH {
    font-family: "Poppins", sans-serif;
    color: #333;
    padding-top: 10px;
    padding-left: 50px;
    display: flex;
    align-items: center;
}

.Admin_xpercent__3F8nH img {
    height: 30px;
    width: 30px;
}

.Admin_xpercent__3F8nH span {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .4em;
    cursor: pointer;
}

.Admin_signUpTitle__3gAov {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 30px;
    font-weight: 900;
    color: #333;
    letter-spacing: 0.2em;
    padding-left: 50px;
    margin-bottom: 30px;
}

.Admin_selectModel__1Ludt {
    font-family: 'Noto Sans KR', sans-serif;
}

.Admin_signUpInput__2aGLV {
    width: 100%;
    padding: 10px 20px 10px 20px;
    font-weight: 600;
}

.Admin_signUpInput__2aGLV::-webkit-input-placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500
}

.Admin_signUpInput__2aGLV::placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500
}

.Admin_signUpInput__2aGLV input::-webkit-input-placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500
}

.Admin_signUpInput__2aGLV input::placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500
}

.Admin_signUpButton__2OGVB {
    background-color: #0041ed;
    font-family: 'Noto Sans KR', sans-serif;
    border: none;
    padding: 10px 20px;
    margin: 0 50px;
    text-align: center;
    color: white;
    width: 100%
}

@media only screen and (max-width: 1516px) {
    .Admin_xpercent__3F8nH img {
        height: 25px;
        width: 25px;
    }

    .Admin_xpercent__3F8nH span {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }
}

/* @media only screen and (max-width: 1140px) {
    .xpercent img {
        height: 20px;
        width: 20px;
    }
    
    .xpercent span {
        font-size: 25px; 
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }
} */

@media only screen and (max-width: 1205px) {
    .Admin_xpercent__3F8nH img {
        height: 30px;
        width: 30px;
    }

    .Admin_xpercent__3F8nH span {
        font-size: 40px;
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }
}

@media only screen and (max-width: 480px) {
    .Admin_xpercent__3F8nH img {
        height: 20px;
        width: 20px;
    }

    .Admin_xpercent__3F8nH span {
        font-size: 25px;
        font-weight: bold;
        letter-spacing: .4em;
        cursor: pointer;
    }

    .Admin_infoHeader__1Ap7D .Admin_top__1cGq9 {
        text-align: center;
        padding: 0px;
        font-size: 60px;
        font-weight: bold;
        margin-left: 80px;
        line-height: 88px;
    }

    .Admin_infoHeader__1Ap7D .Admin_bottom__H3Foa {
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        padding: 10px 0 10px 0;
        margin-right: 80px;
    }
}

.Admin_date_container__3ij5q {
    background-color: #eaf9f1;
    padding: 7px 10px;
    border-radius: 10px;
}

.Admin_active_date__2reQj {
    font-weight: bold;
    color: #46c795;
    font-size: 15px;
    margin: 0px 3px;
}

.Admin_inactive_date__3c25j {
    color: #b2beba;
    font-weight: bold;
    font-size: 15px;
    margin: 0px 3px;
}

.Admin_change_date__NLwp6 {
    cursor: pointer
}

input[type="checkbox"] {
    visibility: hidden;
    display: none;
}

input[type="checkbox"]:checked+.Admin_selectModel__1Ludt {
    /* transform: rotate(360deg); */
    background-color: #000;
}

input[type="checkbox"]:checked+.Admin_selectModel__1Ludt:before {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    background-color: #fff;
}

.Admin_selectModel__1Ludt {
    display: flex;
    width: 60px;
    height: 30px;
    border: 3px solid;
    border-radius: 99em;
    position: relative;
    transition: -webkit-transform 0.75s ease-in-out;
    transition: transform 0.75s ease-in-out;
    transition: transform 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    cursor: pointer;
}

.Admin_selectModel__1Ludt:before {
    transition: -webkit-transform 0.75s ease;
    transition: transform 0.75s ease;
    transition: transform 0.75s ease, -webkit-transform 0.75s ease;
    /* transition-delay: 0.5s; */
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #000;
    border-radius: 50%;
    top: 5px;
    left: 5px;
}

.Admin_toggleOff__2tWoY:before {
    content: "OFF";
    left: 24px;
    position: absolute;
    top: 2px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 600;
    opacity: 1;
    transition: opacity 0.75s ease;
}

.Admin_toggleOn__3hOdH:after {
    content: "ON";
    right: 28px;
    position: absolute;
    color: white;
    top: 2px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 600;
    opacity: 0;
    transition: opacity 0.75s ease;
}

input[type="checkbox"]:checked+.Admin_selectModel__1Ludt .Admin_toggleOn__3hOdH:after {
    opacity: 1;
}

input[type="checkbox"]:checked+.Admin_selectModel__1Ludt .Admin_toggleOff__2tWoY:before {
    opacity: 0;
}

.Admin_selectSector__3mL_F label {
    display: inline-block;
    background-color: white;
    color: #adadad;
    border-radius: 10px;
    white-space: nowrap;
    margin: 3px 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s;
    padding: 2px 6px;
    cursor: pointer;
    margin: 1px;
}

.Admin_selectSector__3mL_F input[type="checkbox"]:checked+.Admin_kospi__3IXok {
    background-color: #ff533e;
    color: #fff;
    transition: all .2s;
}

.Admin_selectSector__3mL_F input[type="checkbox"]:checked+.Admin_kosdaq__3oACy {
    background-color: #ff533e;
    color: #fff;
    transition: all .2s;
}

.Admin_selectSector__3mL_F input[type="checkbox"] {
    display: absolute;
}

.Admin_selectSector__3mL_F input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.Admin_selectSector__3mL_F input[type="checkbox"]:focus+label {
    border: 2px solid #e9a1ff;
}

.Admin_inputNotChanged__3w2g1 input {
    color: black !important;
}

.Admin_inputChanged__2kN8x input {
    color: blue !important;
}



.Admin_analytics-card-list__24KpG {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centers the cards horizontally */
  }
  
  .Admin_analytics-card__31WCq {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    text-align: center;
    background-color: #f5f5f5;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(20% - 20px); /* 5 cards in a row */
    max-width: calc(20% - 20px); /* 5 cards in a row */
  }
  
  .Admin_analytics-card-label__7qglH {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .Admin_analytics-card-value__1_VGQ {
    font-size: 24px;
    font-weight: bold;
  }
.body{
  height: 100vh;
}
.App {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 110vh;
    background-color: #f2f2f2;
  }
  .container_app{
  
    background-color: #fff;
    width: 459px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 30px */
  
   
  }
  .title_h1 {
      /* padding-top: 34px; */
  color:#2b53ba;
  text-align: center;
  padding-top: 38px;
  }
  .text_p{
    font-size: 23;
    text-align: center;
    color:#000;
  }
  .total_user_p{
    margin-top: 23px;
  }
  .total_user_app{
    color: #fc8a55;
    font-size: 20;
  }
  .test_btn {
    position: absolute;
    bottom: 20px;
    padding: 10px 100px;
    border: none;
    background-color: #4c8be7;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
  }
  
  .title_h4 {
    width: 78%;
    padding-top: 23px;
  color:#2b53ba;
  text-align: center;
  }
  
  .tip_progress_container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
  }
  
  .tip_progress-bar {
    /* display: flex; */
    width: 70%;
    height: 10px;
    background-color: #ddd;
    /* margin-top: 20px; */
    position: relative;
    align-items: center;
   
  }
  
  .tip_progress {
    height: 100%;
    background-color: #4CAF50;
  }
  
  .screen-number {
    color: #000;
    font-size: 14px;
  }
  
  .close {
  
    padding-top: 10px;
  
    /* position: absolute;
    top: 26px;
    right: 34px; */
    cursor: pointer;
  }
  
  svg {
    display: block;
  }
  .header_container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .inner_content_container{
  
    width: 100%;
    align-items: center;
    
    flex-direction: column;
  
  }
  .header_left_container{
    
  
  }
  .header_h3{
    color: #2b53ba;
   /* padding-left: 37px; */
   font-size: 21px;

    margin-top: 29px;
    margin-left: 33px;
    margin-right: 33px;
    
  }
  .header_h4{
    color: #2b53ba;
   /* padding-left: 37px; */
   font-size: 19px;

    margin-top: 29px;
    margin-left: 33px;
    margin-right: 33px;
    text-align: center;
    
  }
  .content_headers{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 22px;
    /* width: 342px */

  }
  .rsltcontent_headers{
  
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 32px;
    width: 342px
}
  .stock_view{
    display: flex;
    flex-direction:row;
    margin-bottom: 15px;
    justify-content: center;
    padding-left: 7px;
    padding-right: 7px;
    position: relative;
    
  }
 

  .stock_view_wrapper
  {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    width: 347px;
    border: 1px solid #2b53ba;
    /* height: 17vh; */
    height: 16vh;
  }
  .stock_view_wrapper:hover
  {
   
    border: 1px solid #ccc;

  }

  .stock_view_left{
    width: 57%;
    text-align: center;
        padding: 0;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
  }
  .tip_stock_img{

  }
  .tip_stock_name{
    /* padding-top: 22px; */
  }
  .tip_stockimgtext{
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      
    margin-top: -4px;
  }
  .stock_view_right{
    width: 32%;
  }
  .stock_percentage{
    text-align: center;
    padding: 0;
      margin: 0;
  }.loader{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    
  }
  .progress_text{
    color:#3b67aa;
    font-weight: bold;
  }
  .result_container{
    width: 350px;

    background-color: #5d97d9;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 5px;
    /* padding-bottom: 26px; */
    
  }
  .result_header_title{
    color: #fff;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    /* margin: auto; */
    padding: 17px;
  }
  .left_content_header{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .right_content_header{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .result_p_text{
    font-weight: bold;
    font-size: medium;
    width: 160px;
    text-align: center;
  }
  .staticImg{
    margin-top: 15px;
    background-color: #000;
    margin-bottom: 25px;
  }
  
  .result_btn{
    background-color: #4c8be7;
 
    padding: 10px 77px;
    border: none;
    border-radius: 7px;
    color: white;
    font-size: 19px;
    font-weight: bold;
    cursor: pointer;
  }
  .sns_bottom_appstore
  {
  
    align-items: center;
   display: flex;
   justify-content: center;
  }
  .sns_bottom{
    
    margin-top: 20px;
    align-items: center;
    
   display: flex;
   justify-content: center;
   border:1px solid #eee;
   border-radius: 10px;
   padding: 0.5rem 1.8rem;
   width: 345px;

  
  }.sns_title{
    align-items: center;
    width: 250px;
    justify-content: center;
    text-align: center;
    
  }
  .sns_icon_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  /* width: 200px; */
  
  }
  .reload_box{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
   padding:0rem 0.5rem;
    height: 5vh;
    cursor: pointer;
  }
  .reload_box:hover{
    background-color: #f5f5f5;
    color: black;
  }
  .bottom_text{
    font-size: 10px;
    font-weight: "100";
    color:#666667;
    width: 350px;
    text-align: center;
    padding-bottom: 10px;
  
  }
  .red_class{
    color: #e7544c,
  }
  .gray_class{
    color:#000
  }
  .blue_class{
    color:#5d97d9,
  }
  .img_container{
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .tip_askaicontainer{
    margin-top: 20px;
    border:1px solid #5d97d9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1.8rem;
    padding-top: 0.6rem;
    cursor: pointer;
     margin-bottom: 20px;
   

  }
  .tip_askaicontainer:hover{
    border:1px solid #eee;
    background-color: #eee;
   
  }
  .tip_askaicontainer:active{
    border:1px solid #eee;
    background-color: #eee;
  }
.tip_askaishannon_box{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}
.tip_askaishannon_left{
  padding-right: 20px;
}
.tip_askaishannon_left>p{
  color:#5d97d9;
  font-size: 20px;
  font-weight: bold;
  
}
.tip_askaishannon_right>img{
  width:100px ;



}
  @media only screen and  (max-width: 375px) {
    .stock_view {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      justify-content: center;
      padding-left: 7px;
      padding-right: 7px;

  }
  .tip_stockimgtext >img{
    width: 70px;
  }
  .stock_view_wrapper
  {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    width: 347px;
    border: 1px solid #c7c7c7;
    height: 19vh;
    /* height: 14vh; */
  }
  .App {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  .stock_view_wrapper:hover
  {
   
    border: 1px solid #ccc;

  }


  .tip_askaicontainer:hover{

    border:1px solid #eee;
    background-color: #eee;
   
  }
  .tip_askaicontainer:active{
    border:1px solid #eee;
    background-color: #eee;
  }
   } 
.NotFoundPage_layout__1uojZ {
    background-color: rgb(239, 240, 240);
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.NotFoundPage_image__1HDvn {
    width: 80vw;
}
.NotFoundPage_link__22R3l:link, .NotFoundPage_link__22R3l:visited, .NotFoundPage_link__22R3l:hover, .NotFoundPage_link__22R3l:active{
    color: rgb(38, 48, 60);
    font-size: 16px;
    text-decoration: none;
    width: 140px;
}
.NotFoundPage_button__31ZXc {
    border-radius: 50px;
    background-color: #990000;
    justify-content: center;
    color: white;
}

@media (min-width: 600px) {
    .NotFoundPage_image__1HDvn {
        width: 600px;
    }
}
