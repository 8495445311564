.wrapper {
    max-width: 1024px;
    justify-content: center;
}

.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.inLayer {
    background-color: white;
    padding: 10px 0;
}

.button {
    margin-top: 10px;
    width: 100%;
    height: 40px;
}