@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body {
    font-family: 'Noto Sans KR', sans-serif;
}

.leftdiv {
    float: left;
    width: 500px;

}

.rightdiv {
    float: left;
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
}

/*div {*/
/*    color: black;*/
/*    background-color: rgb(223, 217, 217);*/
/*    height: 50px;*/
/*    font-size: 12px;*/
/*}*/

.btn0 {
    margin-right: 50px;
    width: 300px;
    height: 40px;
}

.btn1 {
    margin-left: 50px;
    width: 300px;
    height: 40px;
}

.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 20px;
    border-radius: 3px;
}

.inLayer {
    background-color: white;
    padding: 10px;
}

.p_header {
    padding: 10px 0;
}

.section {
    margin: 30px;
}

.content {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.main_divider {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}

.content_divider {
    margin: 5px 0;
}

.free_table {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.button {
    width: 100%;
    border: none;
    background-color: #990000;
    color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 500;
    padding: 20px;
    font-size: 20px;
}


.termsAndAgreements {
    width: 100%; 
    height: 70vh; 
    margin-bottom: 20px; 
    background-color: #EDEDED;
    border: none;
    resize: none;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500;
    padding: 10px;
}

.termsAndAgreementsTitle {
    width: 100%; 
    margin-top: 20px; 
    background-color: #EDEDED;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 700;
    padding: 10px;
    font-size: 30px;
}

.termsAndAgreements:focus {
    border: none;
    outline: none;
}


.info {
    margin: 0px 0px 50px 0px;
    background-color: #505050;
    border-radius: 10px;
}

.info_title {
    padding: 20px 20px 0 20px;
    color: white;
    font-size: 25px;
    font-weight: 600;
}

.info_content {
    padding: 20px;
    color: white;
    font-size: 13px;
    font-weight: 600;
}
