.simulationTransactionImage {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

@media only screen and (max-width: 500px)
{
    .simulationTransactionImage {
        width: 100%;
    }
}