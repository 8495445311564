.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 12px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 10px 0;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    cursor: pointer;
}
.layer:hover {
    transform: scale(1.1);
    transition: scale 1s;
}
.layer:hover .image {
    transform: scale(1.2);
    transition: scale 4s ease-in-out 1s;
}
.layer:hover .text {
    font-weight: bold;
    transform: scale(1.1);
    transition: scale 1s;
}

.image {
    width: 35px;
    height: 35px;
    transition:  scale 1s;
}
.text {
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 14px;
}

.button {
    background-color: #EDEDED;
    width: 100%;
    height: 60px;
    padding: 6px;
    margin: 6px 0;
}

.selectedButton {
    background-color: #990000;
    width: 100%;
    height: 60px;
    padding: 6px;
    margin: 6px 0;
}

.disabledButton {
    background-color: #4a4a4a;
    width: 100%;
    height: 60px;
    padding: 6px;
    margin: 6px 0;
}