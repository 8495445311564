body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.deep_bg {
  background-color: #990000 !important;
}
.ant-modal-content {
  background-color: #EDEDED!important;
}
.ant-modal-body {
  padding: 0px!important;
}

.ant-modal-content {
  border-radius: 10px !important; 
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-right: 15px;
  margin-bottom: 15px;
}

/* Admin page create coupon css */
.create_coupon .ant-modal-content {
  background-color: white !important;
}

.create_coupon .ant-form-item-label label {
  font-weight: bold;
}

.create_coupon .ant-radio-group {
  width: 100%;
  text-align: center;
}

.create_coupon .ant-radio-group label {
  width: 45%;
  text-align: center;
  padding: 20px;
  font-size: 15px;
  line-height: 0px;
  margin: 5px;
  font-weight: bold;
}

.create_coupon .ant-checkbox-group {
  width: 100%;
  text-align: center;
}

.create_coupon #coupon_use_membership label {
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 45%;
  text-align: center;
}

.create_coupon #coupon_use_payment_month label {
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 45%;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 5px;
}

.create_coupon .ant-checkbox-group .ant-checkbox-wrapper-checked {
  border-color: #096dd9 !important;
}

.create_coupon .ant-picker-range {
  border-radius: 10px;
}

.create_coupon .ant-picker-range #coupon_use_period_date {
  padding: 5px;
}
/* Admin page create coupon css end */

/* Payment page payment and coupon use css */
.payment_radio {
  display: flex;
  align-items: center;
}

.payment_radio .ant-radio-group {
  width: 90%;
  text-align: center;
}

.payment_radio .ant-radio-group label {
  text-align: center;
  padding: 20px;
  font-size: 15px;
  line-height: 0px;
  margin: 5px;
  font-weight: bold;
  width: 23%;
}

.payment_coupon_code_input input {
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.payment_collapse .ant-collapse-header {
  background-color: #6FDFDF;
  width: 100%;
  border-radius: 5px !important;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  justify-content: center;
  color: 'white'
}

@media only screen and (max-width: 992px) {
  .payment_radio {
    flex-direction: column;
  }

  .payment_radio .ant-radio-group {
    margin-top: 20px;
  }

  .payment_radio .ant-radio-group label {
    width: 45%;
  }
}

@media only screen and (max-width: 430px) {
  .payment_radio .ant-radio-group label {
    text-align: center;
    padding: 20px;
    font-size: 15px;
    line-height: 0px;
    margin: 5px;
    font-weight: bold;
    width: 100%;
  }
}

/* Payment page payment and coupon use css end */

/* Profile */
.payment_info .btn:hover {
  color: white !important;
}

.update_info .btn:hover {
  color: white !important;
}

.else_info .btn:hover {
  color: white !important;
}
/* Profile page end */

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333 !important;
  align-items: center;
  display: flex;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff !important;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

/* Admin page create battle css */
.create_battle .ant-modal-content {
  background-color: white !important;
}
/* Admin page create battle css end */