.profile-background-img {
    width: 100%;
    height: 180px;
}

.profile-top-view {
    text-align: center;
}

.profile-person {
    width: 170px;
    height: 170px;
    margin: 50px auto 10px auto;
}

.profile-person-img {
    width: 170px;
    height: 170px;
    /* background-color: #b1b1b1; */
    background-color: transparent;
    border-radius: 50%;
}

.profile-name {
    font-size: 16px;

    color: #838080;
}

.profile-username {
    font-size: 22px;
}

.invite-container {
    margin-top: 20px;
    margin-bottom: 0;
    display: inline-block;
}

.invite-right {
    margin-left: 50px;
}

.invite-row {
    margin-top: 22px;
}

.invite-item {
    font-size: 18px;
}

.invite-name {
    font-size: 12px;
    color: #838080;
}

.profile-container {
    padding-bottom: 1.8em;
}

.profile-specific-view {
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
}

.profile-hr {
    border-top-color: #838080;
    border-top-style: solid;
    margin: auto auto 20px;

    /*width: 60%;*/
    align-self: center;
    text-align: center;
}

.profile-title {
    font-size: 13px;
    color: #ababab;
    margin: 10px 0;
}

.profile-content {
    font-size: 16px;
}

.profile-logout {
    border: #2f2f2f solid 0.5px;
    /* color: #2f2f2f; */
    background-color: #2f2f2f;
    border-radius: 15px;
    text-align: center;
    width: 60%;
    margin: 30px auto;

    cursor: pointer;
}

.logout-button:link {
    color: white;
}

.logout-button:visited {
    color: white;
}

.logout-button:hover {
    color: white;
}