.common-table {
    width: 80%;
    margin: 0 auto;
    /* text-align: center; */
    text-align: left;
    border-spacing: 0;
  }
  
  .common-table-header-column {
    border-bottom: 2px solid #e8e8e8;
    padding: 0;
    font-size: 16px;
    padding: 10px 5px;
    font-weight: bold;
  }

  .common-table-header-column:nth-child(1) {
    width: 70px;
    text-align: center;
  }
  .common-table-header-column:nth-child(2) {
    width: auto;
  }
  .common-table-header-column:nth-child(3) {
    width: 100px;
  }

  .common-table-column:nth-child(1) {
    text-align: center;
  }
  
  /* .common-table-row:hover {
    background-color: #eceaea;
    cursor: pointer;
  } */
  
  .common-table-column {
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 5px;
  }