.leftdiv {
    float: left;
    width: 500px;

}

.rightdiv {
    float: left;
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
}

/*div {*/
/*    color: black;*/
/*    background-color: rgb(223, 217, 217);*/
/*    height: 50px;*/
/*    font-size: 12px;*/
/*}*/

.btn0 {
    margin-right: 50px;
    width: 300px;
    height: 40px;
}

.btn1 {
    margin-left: 50px;
    width: 300px;
    height: 40px;
}

.card_layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    margin: 15px;
    border-radius: 3px;
}

.layer {
    background-color: #EDEDED;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    /* margin: 15px; */
    border-radius: 3px;
}

.inLayer {
    background-color: white;
    padding: 10px 0;
}

.p_header {
    padding: 10px 0 0 0;
    margin: 0px 5px
}

.section {
    margin: 30px;
}

.content {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 5px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.main_divider {
    margin: 5px 0;
    border-top: 1px solid #DDD;
}

.content_divider {
    margin: 5px 0;
}

.free_table {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    margin: 0px 0px 15px 0px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.button {
    width: 100%;
    height: 40px;
    /* margin: 5px; */
    padding: 6px;
    color: white;
    font-weight: 600;
}

.disabledButton {
    background-color: #4a4a4a;
    width: 100%;
    height: 40px;
    padding: 6px;
    margin: 0;
}