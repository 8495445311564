.header,
.header>.row {
    justify-content: center;
}

.header {
    padding: 0 !important;
}

.header.comp {
    display: none;
}

.header.padder {
    padding-bottom: 63px;
}

.nav-item .nav-link {
    padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    color: #f7f5f5;
    opacity: 0.8;
    text-decoration: none;
    font-size: 1.1rem;
    transition: all 0.2s ease;
}


.header-head {
    top: 0;
    left: 0;
    background: #900;
    width: 100%;
    height: 60px;
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
    box-shadow: 0 0 7px #2f2f2f;
}

.header-head.about {
    background: #3C3D3F;
}

.header-head.fixed {
    position: fixed;
    background: #900;
    transition: background 0.5s;
}

.header-icons {
    margin: 0 10px 0 5px;
}

.header-title {
    /* text-align: right; */
    color: #ffffff;
}

.header-title-hr {
    border-color: #e7e7e7;
    border-style: solid;
    border-width: 0.7px;
    width: 30%;
    margin: 5px auto;
}

#screen-title {
    font-weight: bolder;
    font-size: 1.5rem;
    text-shadow: 2px 2px 2px #423d3d80;
}

.header-dropdown-button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    /* transform: translate(-100%, 60%); */
    /* font-size: 1.8rem; */
    cursor: pointer;
}


.app_link {
    margin-top: 10px;
}


/*********************************/
/*    NAVBAR                     */
/*********************************/

.navbar-custom {
    background-color: #900;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    font: 700 0.875rem/0.875rem "Open Sans", sans-serif;
    transition: all 0.2s;
}

.navbar-custom .container {
    max-width: 87.5rem;
}

.navbar-custom .navbar-brand.logo-image img {
    width: 12rem;
    height: 3rem;
}

/*.navbar-custom .navbar-brand.logo-image.active img {*/
/*	width: 12rem;*/
/*	height: 3rem;*/
/*}*/

.navbar-custom .navbar-brand.logo-text {
    font: 700 2rem/1.5rem "Open Sans", sans-serif;
    color: #fff;
    ;
    text-decoration: none;
}

.navbar-custom .navbar-nav {
    margin-top: 0.75rem;
    /* margin-bottom: 0.5rem; */
}

.navbar-custom .nav-item .nav-link {
    padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    color: #f7f5f5;
    opacity: 0.8;
    text-decoration: none;
    font-size: 1.1rem;
    transition: all 0.2s ease;
}



.navbar-custom .nav-item .nav-link.active {
    color: #fff;
    opacity: 1;

    display: flex;
    align-items: center;
}

.nav-menu {
    list-style: none;
    padding-left: 0px;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: -1;
}

.nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
}

/* Dropdown Menu */
.navbar-custom .dropdown.active>.dropdown-menu {
    display: block;
    /* this makes the dropdown menu stay open while hovering it */
    min-width: auto;
    animation: fadeDropdown 0.2s;
    /* required for the fade animation */
}

@keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.navbar-custom .dropdown-toggle:focus {
    /* removes dropdown outline on focus */
    outline: 0;
}

.navbar-custom .dropdown-menu {
    margin-top: 0;
    border: none;
    border-radius: 0.25rem;
    background-color: #900;
}

.navbar-custom .dropdown-item {
    color: #f7f5f5;
    opacity: 0.8;
    font: 700 0.875rem/0.875rem "Open Sans", sans-serif;
    text-decoration: none;
}

.container .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-custom .dropdown-item:hover {
    background-color: #900;
    color: #fff;
    opacity: 1;
}

.navbar-custom .dropdown-items-divide-hr {
    width: 100%;
    height: 1px;
    margin: 0.75rem auto 0.725rem auto;
    border: none;
    background-color: #c4d8dc;
    opacity: 0.2;
}

/* end of dropdown menu */

.navbar-custom .nav-item .btn-outline-sm {
    margin-top: 0.15rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    border: 0.125rem solid #fff;
    color: #fff;

}

.navbar-custom .nav-item .btn-outline-sm:hover {
    background-color: #fff;
    color: #900;
}

.navbar-custom .navbar-toggler {
    padding: 0;
    border: none;
    color: #fff;
    font-size: 2rem;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times {
    display: none;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars {
    display: inline-block;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars {
    display: none;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times {
    display: inline-block;
    margin-right: 0.125rem;
}